import { BodyXsmallStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"

export const BodyXSmall = ({ content }: BodyXsmallStoryblok) => {
  return (
    <Paragraph size="xsmall" className="whitespace-pre-wrap">
      {content}
    </Paragraph>
  )
}

export default BodyXSmall
