import { StoryblokComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { SeekCareSectionStoryblok } from "@storyblok-types"

import CardSection from "@ui-library/card/CardSection"

export const SeekCareSection = ({ blok }: BlokProps<SeekCareSectionStoryblok>) => {
  return (
    <>
      <CardSection
        {...withStoryblokEditable(blok)}
        title={blok.title}
        content={blok.content}
        cards={blok.seek_care_cards?.map(card => <StoryblokComponent key={card._uid} blok={card} />)}
      />
      {blok.info_section?.map(info => <StoryblokComponent key={info._uid} blok={info} />)}
    </>
  )
}
