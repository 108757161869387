const ArrowForwardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.3 15.3C7.11667 15.1167 7.02067 14.8834 7.012 14.6C7.004 14.3167 7.09167 14.0834 7.275 13.9L12.175 9.00005H1C0.716667 9.00005 0.479 8.90405 0.287 8.71205C0.0956668 8.52071 0 8.28338 0 8.00005C0 7.71672 0.0956668 7.47905 0.287 7.28705C0.479 7.09572 0.716667 7.00005 1 7.00005H12.175L7.275 2.10005C7.09167 1.91672 7.004 1.68338 7.012 1.40005C7.02067 1.11672 7.11667 0.883382 7.3 0.700048C7.48333 0.516715 7.71667 0.425049 8 0.425049C8.28333 0.425049 8.51667 0.516715 8.7 0.700048L15.3 7.30005C15.4 7.38338 15.471 7.48738 15.513 7.61205C15.5543 7.73738 15.575 7.86672 15.575 8.00005C15.575 8.13338 15.5543 8.25838 15.513 8.37505C15.471 8.49172 15.4 8.60005 15.3 8.70005L8.7 15.3C8.51667 15.4834 8.28333 15.575 8 15.575C7.71667 15.575 7.48333 15.4834 7.3 15.3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowForwardIcon
