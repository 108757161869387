const LeftArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.875 15.3001L0.275 8.70008C0.175 8.60008 0.104 8.49175 0.0619998 8.37508C0.0206665 8.25842 0 8.13342 0 8.00008C0 7.86675 0.0206665 7.74175 0.0619998 7.62508C0.104 7.50842 0.175 7.40008 0.275 7.30008L6.875 0.700082C7.05833 0.516749 7.28733 0.420749 7.562 0.412083C7.83733 0.404083 8.075 0.500082 8.275 0.700082C8.475 0.883416 8.57933 1.11242 8.588 1.38708C8.596 1.66242 8.5 1.90008 8.3 2.10008L3.4 7.00008H14.575C14.8583 7.00008 15.096 7.09575 15.288 7.28708C15.4793 7.47908 15.575 7.71675 15.575 8.00008C15.575 8.28342 15.4793 8.52075 15.288 8.71208C15.096 8.90408 14.8583 9.00008 14.575 9.00008H3.4L8.3 13.9001C8.48333 14.0834 8.57933 14.3167 8.588 14.6001C8.596 14.8834 8.5 15.1167 8.3 15.3001C8.11667 15.5001 7.88333 15.6001 7.6 15.6001C7.31667 15.6001 7.075 15.5001 6.875 15.3001Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LeftArrow
