"use client"

import { useConfigStore } from "@/store/config-store"
import { ConfigStoryblok } from "@/types/component-types"
import SendIcon from "@layout/icons/SendIcon"
import SpinnerIcon from "@layout/icons/SpinnerIcon"
import { trackEvent } from "@lib/amplitude/amplitude"
import { sendBrazeRequest } from "@lib/sendBrazeRequest"
import ConfirmDialog from "@ui-library/dialog/ConfirmDialog"
import Richtext from "@ui-library/richtext/Richtext"
import { H3 } from "@ui-library/typography/Headings"
import { cn } from "@utils/utils"
import { usePathname } from "next/navigation"
import { useState } from "react"
import { Paragraph } from "../typography/Body"

interface ApiResponse {
  status: boolean
  message?: string
}

type Message = {
  title: string
  content: string
  error: boolean
}

export const SubscriptionSection = () => {
  const pathname = usePathname()
  const config = useConfigStore.getState().config as ConfigStoryblok
  const [email, setEmail] = useState<string>("")
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [result, setResult] = useState<ApiResponse | null>(null)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [message, setMessage] = useState<Message>({ title: "", content: "", error: false })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSending(true)
    e.preventDefault()
    const response = await sendBrazeRequest(email)
    setResult(response)
    setIsSending(false)

    if (response.status) {
      setMessage({ title: "Grattis!", content: response.message, error: false })
      trackEvent("newsletter.signup.succeeded", { page_path: pathname })
      handleDialogState(true)
    }
  }

  const handleDialogState = (open: boolean) => {
    setOpenDialog(open)
  }

  return (
    <div className="mt-8 rounded-2xl bg-secondary-10 px-4 py-12 text-center text-on-secondary-10 lg:mt-16">
      <form className="mx-auto flex max-w-md flex-col" onSubmit={handleSubmit}>
        <H3>{config.newsletter_title}</H3>
        <Richtext bodySmall content={config.newsletter_content} className="mb-6" />

        <div className="relative flex items-center justify-between">
          <input
            type="email"
            id="email"
            placeholder={config?.newsletter_placeholder}
            value={email}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={e => setEmail(e.target.value)}
            disabled={isSending}
            className={cn(
              "h-12 w-full rounded-md border border-on-surface bg-surface px-4 text-on-surface outline-none transition-all",
              isSending && "pointer-events-none text-neutral-50",
              isFocus && "border-on-secondary-10 shadow-inputInner",
              result && !result.status && "border-warning-50"
            )}
          />
          <button
            type="submit"
            className="absolute right-4 text-on-surface"
            disabled={isSending}
            aria-label="Signa upp till nyhetsbrev"
          >
            {isSending ? <SpinnerIcon className="h-8 w-8 animate-spin" /> : <SendIcon />}
          </button>
        </div>

        {result && (
          <Paragraph size="small" className={cn("w-full pt-2 text-left", !result.status && "text-warning-50")} asChild>
            <span>{!result.status && <>{result.message}</>}</span>
          </Paragraph>
        )}

        <Paragraph size="xsmall" className="mt-4" asChild>
          <span>{config.newsletter_policy}</span>
        </Paragraph>
      </form>

      <ConfirmDialog
        title={message.title}
        message={message.content}
        error={message.error}
        open={openDialog}
        onClose={() => handleDialogState(false)}
      />
    </div>
  )
}

export default SubscriptionSection
