import { useEffect } from "react"

/**
 * Hook that listens to window scroll events and calls the provided callback.
 *
 * @param callback function to be called when the window is scrolled.
 */
export const useWindowScroll = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener("scroll", callback)
    return () => {
      window.removeEventListener("scroll", callback)
    }
  }, [callback])
}
