import { FindHereData } from "@/components/storyblok-routes/clinics/MapsSection"
import { Paragraph } from "@ui-library/typography/Body"
import { H4 } from "@ui-library/typography/Headings"

export const FindHereDesktop = ({
  public_transport_title,
  public_transport_content,
  car_title,
  car_content
}: FindHereData) => (
  <div className="hidden lg:flex lg:justify-between lg:space-x-12">
    <div className="max-w-full">
      <H4>{public_transport_title}</H4>
      <Paragraph size="medium">{public_transport_content}</Paragraph>
      <H4>{car_title}</H4>
      <Paragraph size="medium">{car_content}</Paragraph>
    </div>
  </div>
)
