interface ContentItem {
  text: string
}

type ImageSectionStoryblok = {
  // Define the properties of your ImageSectionStoryblok type here
}

type ParagraphStoryblok = {
  // Define the properties of your ParagraphStoryblok type here
}

type NestedContent = ContentItem | string | ImageSectionStoryblok | ParagraphStoryblok

function extractTextFromContent(content: NestedContent): string {
  if (typeof content === "string") {
    return content
  } else if ("text" in content) {
    return content.text
  } else if (Array.isArray(content)) {
    // Handle the case where content is an array of NestedContent
    return content.map(extractTextFromContent).join(" ")
  } else if (typeof content === "object" && "content" in content) {
    // Check if 'content' property exists using the 'in' operator
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return extractTextFromContent((content as any).content)
  } else {
    // Handle other content types if needed
    return ""
  }
}

export function calculateReadingTimeFromMultidimensionalArray(
  contentArray: NestedContent[] | undefined,
  preamble: string,
  wordsPerMinute = 200
): number {
  if (!contentArray) {
    return 0
  }

  // Extract and concatenate text from the contentArray
  const concatenatedContent = contentArray.map(extractTextFromContent).join(" ")

  // Calculate the reading time as before
  const wordCount = concatenatedContent.trim().split(/\s+/).length
  const preambleWordCount = preamble.trim().split(/\s+/).length
  const totalWordCount = wordCount + preambleWordCount
  const readingTimeMinutes = Math.ceil(totalWordCount / wordsPerMinute)

  return readingTimeMinutes
}
