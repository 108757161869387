import { StoryblokComponent } from "@storyblok/react/rsc"
import Image from "next/image"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { formatDate } from "@lib/formatDate"
import { ArticlePageStoryblok } from "@storyblok-types"

import { useStoryStore } from "@/store/story-store"
import Breadcrumbs from "@blocks/sections/Breadcrumbs"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import { Paragraph } from "@ui-library/typography/Body"

interface ArticlePageProps {
  blok: BlokProps<ArticlePageStoryblok>["blok"]
}

export const PressreleasePage = ({ blok }: ArticlePageProps) => {
  const story = useStoryStore.getState().story
  const publishedDate = formatDate(blok.published_date)

  const grid = {
    xs: 12,
    lg: 8,
    xl: 6
  }

  return (
    <>
      <PageContainer>
        <GridWrapper
          className="mb-0 mt-8 rounded-b-none rounded-t-3xl bg-surface pt-12 md:my-16 md:rounded-b-none md:rounded-t-none md:bg-background md:pt-0"
          {...withStoryblokEditable(blok)}
        >
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <Breadcrumbs slug={story?.full_slug} />
              <h1>{story?.name}</h1>
              <span className="text-neutral-50">{`${publishedDate} ・ Pressmeddelande`}</span>
            </Col>

            <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
              {blok.featured_image && (
                <figure className="relative mb-6 aspect-[1.85] w-full overflow-hidden rounded-2xl lg:mb-10 lg:aspect-[2.5] lg:rounded-3xl">
                  <Image
                    src={blok.featured_image.filename || ""}
                    fill
                    priority
                    alt={blok.featured_image.alt || ""}
                    className="object-cover"
                  />
                </figure>
              )}
            </Col>

            {blok.preamble && (
              <Col grid={grid}>
                <Paragraph size="large">{blok.preamble}</Paragraph>
              </Col>
            )}
          </Row>
        </GridWrapper>

        <GridWrapper background="surface" className="my-0 rounded-none md:my-0 md:rounded-3xl">
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              {blok.body?.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
            </Col>
          </Row>
        </GridWrapper>
      </PageContainer>
    </>
  )
}

export default PressreleasePage
