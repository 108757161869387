const SelectDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.95 2.72495L10.55 11.15C10.45 11.25 10.3417 11.3206 10.225 11.362C10.1083 11.404 9.98333 11.425 9.85 11.425C9.71667 11.425 9.59167 11.404 9.475 11.362C9.35833 11.3206 9.25 11.25 9.15 11.15L0.725 2.72495C0.491667 2.49162 0.375 2.19995 0.375 1.84995C0.375 1.49995 0.5 1.19995 0.75 0.949951C1 0.699951 1.29167 0.574951 1.625 0.574951C1.95833 0.574951 2.25 0.699951 2.5 0.949951L9.85 8.29995L17.2 0.94995C17.4333 0.716617 17.7207 0.59995 18.062 0.59995C18.404 0.59995 18.7 0.72495 18.95 0.97495C19.2 1.22495 19.325 1.51662 19.325 1.84995C19.325 2.18328 19.2 2.47495 18.95 2.72495Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SelectDownIcon
