import { render } from "storyblok-rich-text-react-renderer-ts"

import Image from "next/image"
import Link from "next/link"

import { RichtextStoryblok } from "@storyblok-types"
import OrderedList from "./OrderedList"
import UnorderedList from "./UnorderedList"

import BodySmall from "@/components/blocks/richtext-blocks/BodySmall"
import { Paragraph } from "@ui-library/typography/Body"
import { DisplayHeader, H1, H2, H3, H4 } from "@ui-library/typography/Headings"
import { cn } from "@utils/utils"
import BodyXSmall from "src/components/blocks/richtext-blocks/BodyXSmall"

interface RichtextProps {
  content?: RichtextStoryblok
  preamble?: boolean
  bodySmall?: boolean
  hero?: boolean
  coloredHeadings?: boolean
  neutralParagraphs?: boolean
  className?: string
}

const Richtext = ({
  content,
  preamble,
  bodySmall,
  hero,
  coloredHeadings,
  neutralParagraphs,
  className
}: RichtextProps) => {
  return (
    <article className={cn(className)}>
      {render(content, {
        markResolvers: {
          link: (children, props) => {
            const { href, target, linktype } = props
            if (linktype === "email") {
              return (
                <Link href={`mailto:${href}`} className="inline-link">
                  {children}
                </Link>
              )
            }
            if (href === null) {
              return (
                <Link href="#" target={target || undefined} className="inline-link">
                  {children}
                </Link>
              )
            }
            if (href.match(/^(https?:)?\/\//) || href.match(/^(http?:)?\/\//)) {
              const forcedHttpsHref = href.toLowerCase().startsWith("http://doktor.se") ? "https://doktor.se" : href
              return (
                <Link href={forcedHttpsHref} target={target || undefined} className="inline-link">
                  {children}
                </Link>
              )
            }
            return (
              <Link href={href} target={target || undefined} className="inline-link">
                {children}
              </Link>
            )
          },
          bold: children => <strong>{children}</strong>,
          italic: children => <i className="font-giga font-normal italic">{children}</i>,
          strike: children =>
            bodySmall ? (
              <Paragraph size="xsmall" className="text-neutral-50" asChild>
                <span>{children}</span>
              </Paragraph>
            ) : (
              <s>{children}</s>
            ),
          underline: children => <u>{children}</u>
        },
        nodeResolvers: {
          heading: (children, props) => {
            const { level } = props
            if (level === 1)
              return hero ? (
                <DisplayHeader className={cn("max-lg:[&>br]:hidden", coloredHeadings && "text-primary-50")}>
                  {children}
                </DisplayHeader>
              ) : (
                <H1 className={cn(coloredHeadings && "text-primary-50")}>{children}</H1>
              )
            if (level === 2) return <H2 className={cn(coloredHeadings && "text-primary-50")}>{children}</H2>
            if (level === 3) return <H3 className={cn(coloredHeadings && "text-primary-50")}>{children}</H3>
            return <H4 className={cn(coloredHeadings && "text-primary-50")}>{children}</H4>
          },
          blockquote: children => (
            <blockquote className="mb-5 [&>p]:font-giga [&>p]:text-[2.5rem] [&>p]:italic [&>p]:leading-[2.75rem] [&>p]:before:content-['“'] [&>p]:after:content-['”']">
              {children}
            </blockquote>
          ),
          paragraph: children => {
            if (preamble)
              return (
                <Paragraph size="large" className={cn(neutralParagraphs && "text-neutral-50")}>
                  {children}
                </Paragraph>
              )
            if (bodySmall)
              return (
                <Paragraph size="small" className={cn(neutralParagraphs && "text-neutral-50")}>
                  {children}
                </Paragraph>
              )
            return (
              <Paragraph size="medium" className={cn(neutralParagraphs && "text-neutral-50")}>
                {children}
              </Paragraph>
            )
          },
          bullet_list: children => <UnorderedList>{children}</UnorderedList>,
          ordered_list: children => <OrderedList>{children}</OrderedList>,
          image: (_children, props) => {
            const { src, alt, title } = props
            return (
              <Image src={src || ""} alt={alt} title={title || undefined} width={1024} height={800} className="block" />
            )
          }
        },
        blokResolvers: {
          ["body_xsmall"]: props => <BodyXSmall {...props} />,
          ["body_small"]: props => <BodySmall size="small" {...props} />
        }
      })}
    </article>
  )
}

export default Richtext
