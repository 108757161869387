import { cn } from "@utils/utils"

export const HeadingComponent = ({
  title,
  tag = "h3",
  style = "h3"
}: {
  title?: string
  tag?: keyof JSX.IntrinsicElements | undefined
  style?: string
}): JSX.Element | null => {
  const Element = tag
  if (Element) {
    return <Element className={cn(style)}>{title}</Element>
  }

  return null
}

export default HeadingComponent
