import Richtext from "@/components/ui-library/richtext/Richtext"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { ImageContentSectionStoryblok } from "@/types/component-types"
import { cn } from "@/utils/utils"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Image from "next/image"

export const ImageContentSection = ({ blok }: BlokProps<ImageContentSectionStoryblok>) => {
  const columns = {
    xs: 12,
    lg: 6
  }

  return (
    <GridWrapper>
      <Row alignItems="center">
        {blok.content && (
          <Col grid={columns} className={cn(blok.image_left && "order-2")}>
            <Richtext content={blok.content} />
          </Col>
        )}
        {blok.image?.filename && (
          <Col grid={columns} className={cn(blok.image_left && "order-1")}>
            <Image
              src={blok.image?.filename}
              alt={blok.image?.alt || ""}
              width={640}
              height={0}
              sizes="(max-width: 768px) 100vw, 33vw"
              className="rounded-lg"
            />
          </Col>
        )}
      </Row>
    </GridWrapper>
  )
}

export default ImageContentSection
