import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ParagraphStoryblok } from "@storyblok-types"

import Richtext from "@ui-library/richtext/Richtext"
import { H3 } from "@ui-library/typography/Headings"

export const Paragraph = ({ blok }: BlokProps<ParagraphStoryblok>) => {
  return (
    <div {...storyblokEditable(blok as SbBlokData)}>
      <H3>{blok.title}</H3>
      <Richtext content={blok.content} />
    </div>
  )
}

export default Paragraph
