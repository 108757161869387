import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import dynamic from "next/dynamic"
import Link from "next/link"
import { forwardRef, HTMLAttributes } from "react"

interface ChipLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  url?: string | unknown
  text: string
  icon?: string
}

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

export const ChipLink = forwardRef<HTMLAnchorElement, ChipLinkProps>(
  ({ url, text, icon, className, ...props }, ref) => {
    return (
      <Link
        href={url || "#"}
        prefetch={false}
        ref={ref}
        className={cn(
          "inline-flex w-max cursor-pointer items-center whitespace-nowrap rounded-lg bg-secondary-10 px-6 py-2 text-on-secondary-10 transition-colors",
          icon && "px-4",
          "hover:bg-hover-secondary-10",
          className
        )}
        {...props}
      >
        <span className="flex items-center gap-2 text-center">
          {icon && <DoktorIcon iconName={icon} width={18} height={18} />}
          <Label size="small" className="cursor-pointer">
            {text}
          </Label>
        </span>
      </Link>
    )
  }
)

ChipLink.displayName = "ChipLink"

export default ChipLink
