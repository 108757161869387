import {
  useSeoFaqScript,
  useSeoMedicalOrganizationScript,
  useSeoOpeningHoursScript
} from "@/hooks/seo-schema/useSeoHooks"
import { ClinicPageStoryblok } from "@/types/component-types"
import { BlokProps } from "../StoryblokHelpers"

interface ClinicPageScriptsProps {
  blok: BlokProps<ClinicPageStoryblok>["blok"]
}

export const ClinicPageScripts = ({ blok }: ClinicPageScriptsProps) => {
  const ldFaqScript = useSeoFaqScript(blok)
  const ldMedicalOrganizationScript = useSeoMedicalOrganizationScript(blok)
  const ldOpeningHoursScript = useSeoOpeningHoursScript(blok)

  return (
    <>
      {ldFaqScript && (
        <script
          id="clinicFaqJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldFaqScript)
          }}
        />
      )}
      {ldMedicalOrganizationScript && (
        <script
          id="clinicMedicalOrganizationJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldMedicalOrganizationScript)
          }}
        />
      )}
      {ldOpeningHoursScript && (
        <script
          id="clinicOpeningHoursScript"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldOpeningHoursScript)
          }}
        />
      )}
    </>
  )
}
