import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import Image from "next/image"
import Link from "next/link"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicPageHeroStoryblok } from "@storyblok-types"

import InfoIcon from "@layout/icons/InfoIcon"
import MapIcon from "@layout/icons/MapIcon"
import TimeIcon from "@layout/icons/TimeIcon"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"

export const ClinicPageHero = ({ blok }: BlokProps<ClinicPageHeroStoryblok>) => {
  const showOther = blok.show_other_information

  return (
    <GridWrapper className="row flex flex-col flex-wrap lg:px-4" {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="center" className="order-1">
        <Col grid={{ xs: 12 }} className="text-center">
          <h1 className="display-header mb-0">{blok.heading}</h1>
        </Col>
      </Row>

      <Row justifyContent="center" justifyContentMobile="center" className="order-3 lg:order-2">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
          <Paragraph size="medium">{blok.intro}</Paragraph>
        </Col>
      </Row>

      <figure className="relative order-2 mx-auto mb-4 aspect-[1.6] w-full max-w-footer overflow-hidden rounded-2xl lg:order-3 lg:mb-0 lg:mt-8 lg:aspect-[3] xl:aspect-[3.6]">
        {blok.image.filename && (
          <Image
            src={blok.image.filename || ""}
            fill
            priority
            sizes="100vw"
            alt={blok.image.alt || ""}
            className="object-cover"
          />
        )}
      </figure>

      <Row
        className={cn(
          "order-4 my-4 px-4 lg:my-0",
          { "lg:mb-[-6rem] lg:translate-y-[-6rem]": showOther },
          { "lg:mb-[-4rem] lg:translate-y-[-4rem]": !showOther }
        )}
        justifyContent="center"
        justifyContentMobile="center"
      >
        <InfoBarColumn>
          <Label size="small" asChild className="mb-2 flex items-center justify-start not-italic">
            <em>
              <TimeIcon className="mr-3 max-h-[1.5rem] max-w-[1.5rem]" />
              <span>{blok.open_hours_label ? blok.open_hours_label : "Öppettider"}</span>
            </em>
          </Label>
          <Paragraph size="small" className="inline-block pl-9">
            {blok.open_hours_text}
          </Paragraph>
        </InfoBarColumn>

        <InfoBarColumn>
          <Label size="small" asChild className="mb-2 flex items-center justify-start not-italic">
            <em>
              <MapIcon className="mr-3 max-h-[1.5rem] max-w-[1.5rem]" />
              <span>{blok.location_label ? blok.location_label : "Adress"}</span>
            </em>
          </Label>

          <Paragraph size="small" asChild className="inline-block pl-9 not-italic">
            <address>
              <Link
                href={blok.location_url ? blok.location_url : "#"}
                target={blok.location_url ? "_blank" : ""}
                rel={blok.location_url ? "noopener noreferer" : ""}
              >
                {blok.location_text}
              </Link>
            </address>
          </Paragraph>
        </InfoBarColumn>

        {blok.show_other_information && (
          <InfoBarColumn>
            <Label size="small" asChild>
              <em className="mb-2 flex items-center justify-start not-italic">
                <InfoIcon className="mr-3 max-h-[1.5rem] max-w-[1.5rem]" />
                <span>{blok.other_information_label ? blok.other_information_label : "Övrig information"}</span>
              </em>
            </Label>
            {blok.other_information_text && (
              <Paragraph size="small" className="inline-block pl-9">
                {blok.other_information_text}
              </Paragraph>
            )}
          </InfoBarColumn>
        )}
      </Row>
    </GridWrapper>
  )
}

export default ClinicPageHero

const InfoBarColumn = ({ children }: { children: React.ReactNode }) => {
  return (
    <Col
      grid={{ xs: 12, lg: 4 }}
      className="my-0 bg-primary-70 first:rounded-t-2xl last:rounded-b-2xl lg:first:rounded-l-2xl lg:first:rounded-tr-none lg:last:rounded-r-2xl lg:last:rounded-bl-none"
    >
      <div className="w-full max-w-full p-4 text-on-primary-70 lg:p-8">{children}</div>
    </Col>
  )
}
