import { cn } from "@utils/utils"
import { forwardRef, HTMLAttributes } from "react"

interface RowProps extends HTMLAttributes<HTMLDivElement> {
  alignItems?: "start" | "center" | "end" | "baseline" | "stretch"
  alignItemsMobile?: "start" | "center" | "end" | "baseline" | "stretch"
  justifyContent?: "start" | "end" | "center" | "between" | "around" | "evenly" | "stretch"
  justifyContentMobile?: "start" | "end" | "center" | "between" | "around" | "evenly" | "stretch"
  relative?: boolean
}

const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    {
      children,
      className,
      alignItems = "stretch",
      alignItemsMobile = "stretch",
      justifyContent = "start",
      justifyContentMobile = "start",
      relative,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "mx-auto flex w-full max-w-grid flex-row flex-wrap",
          relative && "relative",
          `items-${alignItemsMobile} md:items-${alignItems}`,
          `justify-${justifyContentMobile} md:justify-${justifyContent}`,
          className
        )}
        {...props}
      >
        {children}
      </div>
    )
  }
)

Row.displayName = "Row"

export default Row
