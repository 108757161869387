import * as Accordion from "@radix-ui/react-accordion"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { VaccinationLocationDateStoryblok, VaccinationLocationStoryblok } from "@storyblok-types"

import { sanitizeString } from "@/lib/sanitizeString"
import MapIcon from "@layout/icons/MapIcon"
import { formatDate } from "@lib/formatDate"
import { groupDatesByMonth } from "@lib/groupDatesByMonth"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"
import { cn, filterDatesNext30Days, findClosestDate } from "@utils/utils"
import Link from "next/link"
import React, { useMemo } from "react"

type VaccinationLocationProps = {
  blok: BlokProps<VaccinationLocationStoryblok>["blok"]
  isPreview: boolean
}

export const VaccinationLocation = ({ blok }: VaccinationLocationProps) => {
  const filteredDates = filterDatesNext30Days<VaccinationLocationDateStoryblok>(blok.dates)
  const closestDateItem = findClosestDate<VaccinationLocationDateStoryblok>(filteredDates)

  const groupedDates = groupDatesByMonth(filteredDates)
  const directionsLink = `http://www.google.com/maps/place/${blok.latitude},${blok.longitude}`

  const informationOutput = closestDateItem ? "Nästa gång vi är här: " : "Tyvärr har vi ingen tid tillgänglig"

  const renderedDates = useMemo(() => {
    return (
      <Accordion.Root type="multiple">
        {groupedDates.map((month, i) => {
          return (
            <Accordion.Item value={month.title} key={i} className="border-b border-b-neutral-30">
              <AccordionTrigger className="py-2">
                <Paragraph size="medium" asChild className="capitalize">
                  <span>{month.title}</span>
                </Paragraph>
              </AccordionTrigger>
              <Accordion.Content className="w-full pb-4 pl-6">
                <ul>
                  {month.dates.map((date, i) => (
                    <li key={i} className="mb-2 last:mb-0">
                      <b>{formatDate(date.date || "", false)}</b>{" "}
                      <span>{`${date.time_from}:00 - ${date.time_to}:00`}</span>
                    </li>
                  ))}
                </ul>
              </Accordion.Content>
            </Accordion.Item>
          )
        })}
      </Accordion.Root>
    )
  }, [groupedDates])

  return (
    <article
      className="mb-8 w-full rounded-2xl bg-surface p-8"
      id={sanitizeString(blok.title)}
      {...storyblokEditable(blok as SbBlokData)}
    >
      <h2 className="text-primary-70">{blok.title}</h2>
      <Link
        href={directionsLink}
        rel="noopener norefferer"
        target="_blank"
        className="mb-4 inline-flex cursor-pointer items-center justify-start"
      >
        <MapIcon width={26} height={26} className="mr-2" />
        <Label size="medium" className="cursor-pointer text-primary-50">
          Hitta hit
        </Label>
      </Link>

      <Paragraph size="medium" className="pl-[34px]">
        {informationOutput}
        {closestDateItem && (
          <b>{`${formatDate(closestDateItem.date || "", false)} kl. ${closestDateItem.time_from}:00`}</b>
        )}
      </Paragraph>

      {closestDateItem && (
        <Accordion.Root className="pl-[34px]" type="single" collapsible>
          <Accordion.Item className="AccordionItem" value="times">
            <AccordionTrigger>
              <Paragraph size="medium" asChild className="text-primary-50">
                <span>Alla tider</span>
              </Paragraph>
            </AccordionTrigger>
            <Accordion.Content className="w-full">{renderedDates}</Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      )}
    </article>
  )
}

export default VaccinationLocation

type AccordionTriggerProps = {
  children: React.ReactNode
  className?: string
}

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Trigger
      className={cn(
        "group/trigger my-4 flex w-full cursor-pointer items-center justify-start border-0 bg-transparent text-left lg:my-0",
        className
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
      <figure className="m-0 flex h-6 w-6 items-center justify-center">
        <ChevronDownIcon
          height={24}
          aria-hidden
          className="transition-transform ease-out group-aria-expanded/trigger:rotate-180"
        />
      </figure>
    </Accordion.Trigger>
  )
)

AccordionTrigger.displayName = "AccordionTrigger"
