"use client"

import SelectMenu, { SelectItem } from "@ui-library/menu/SelectMenu"
import { useEffect, useState } from "react"

import { RegionTemplateStoryblok } from "@storyblok-types"

import { RegionsItem } from "@/types/NonSyncedTypes"
import * as Tabs from "@radix-ui/react-tabs"
import Richtext from "@ui-library/richtext/Richtext"
import Table from "@ui-library/table/Table"
import { Paragraph } from "@ui-library/typography/Body"

interface PriceTableProps {
  placeholder?: string
  regions?: RegionsItem[]
}

export const PriceTableTabs = ({ placeholder, regions }: PriceTableProps) => {
  const [items, setItems] = useState<SelectItem[]>([])
  const [priceTableData, setPriceTableData] = useState<RegionTemplateStoryblok>()

  useEffect(() => {
    if (!regions) return
    const mappedRegions: SelectItem[] = regions.map(region => ({
      value: region.id.toString(),
      label: region.name
    }))

    setItems(mappedRegions)
  }, [regions])

  const handleChange = (value: string) => {
    const selectedRegionData = regions?.find(region => region.id.toString() === value)
    if (selectedRegionData) {
      setPriceTableData(selectedRegionData.content)
    }
  }

  return (
    <>
      <div className="mx-auto w-full max-w-[20rem]">
        <SelectMenu
          placeholder={placeholder}
          items={items}
          param=""
          onChange={value => handleChange(value as string)}
        />
      </div>

      {priceTableData && (
        <Tabs.Root defaultValue="tab2" className="mx-auto w-full max-w-[50rem]">
          <div className="mt-12 w-full rounded-2xl p-4 lg:p-8 lg:shadow-elevation-1">
            <Tabs.List className="mx-auto flex h-16 w-full max-w-[25rem] items-stretch rounded-full bg-neutral-10 p-2">
              <Tabs.Trigger
                value="tab1"
                className="group w-full max-w-6 rounded-full border-0 bg-transparent aria-selected:bg-surface"
              >
                <Paragraph size="medium" className="group-aria-selected:text-primary-50" asChild>
                  <span>{priceTableData.tab_1_title}</span>
                </Paragraph>
              </Tabs.Trigger>
              <Tabs.Trigger
                value="tab2"
                className="group w-full max-w-6 rounded-full border-0 bg-transparent aria-selected:bg-surface"
              >
                <Paragraph size="medium" className="group-aria-selected:text-primary-50" asChild>
                  <span>{priceTableData.tab_2_title}</span>
                </Paragraph>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="tab1">
              {priceTableData.tab_1_price_table && <Table data={priceTableData.tab_1_price_table} />}
              <Paragraph size="small" className="w-full text-center text-neutral-50">
                {priceTableData.tab_1_note}
              </Paragraph>
            </Tabs.Content>

            <Tabs.Content value="tab2">
              {priceTableData.tab_2_price_table && <Table data={priceTableData.tab_2_price_table} />}
              <Paragraph size="small" className="w-full text-center text-neutral-50">
                {priceTableData.tab_2_note}
              </Paragraph>
            </Tabs.Content>
          </div>

          {priceTableData.price_information && (
            <div className="mt-8">
              <Richtext content={priceTableData.price_information} bodySmall />
            </div>
          )}
        </Tabs.Root>
      )}
    </>
  )
}

export default PriceTableTabs
