import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"
import ListItem from "./ListItem"

interface ListSectionProps {
  title: string
  content?: string
  items: { title: string; content: string }[]
  children?: React.ReactNode
}

const ListSection = ({ items, title, content, children }: ListSectionProps) => {
  return (
    <GridWrapper>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-6 text-left lg:mb-10 lg:text-center">
          <h2>{title}</h2>
          <Paragraph size="medium">{content}</Paragraph>
        </Col>

        <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
          <div className="w-full rounded-2xl bg-surface p-6">
            {items.map((item, index) => (
              <ListItem key={index} title={item.title} content={item.content} />
            ))}
          </div>

          <div className="mt-6 flex flex-col items-start gap-4 lg:mt-10 lg:items-center">{children}</div>
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default ListSection
