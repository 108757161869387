import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { PatientfeesSectionStoryblok } from "@storyblok-types"

import { RegionsItem } from "@/types/NonSyncedTypes"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"

import PriceTableTabs from "@ui-library/tabs/PriceTableTabs"

interface PatientfeesSectionProps {
  blok: BlokProps<PatientfeesSectionStoryblok>["blok"]
  regions?: RegionsItem[]
}

export const PatientfeesSection = ({ blok, regions }: PatientfeesSectionProps) => {
  return (
    <GridWrapper background="surface" {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, md: 10, xl: 8 }}>
          <PriceTableTabs placeholder={blok.dropdown_text} regions={regions} />
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default PatientfeesSection
