export const updateURLParameters = (currentURL?: string, updatedParams?: Record<string, string>): string => {
  if (!currentURL) return "Url not set"
  const url = new URL(currentURL)
  const searchParams = new URLSearchParams(url.search)

  // Update the specified parameters and add new ones
  for (const param in updatedParams) {
    if (Object.prototype.hasOwnProperty.call(updatedParams, param)) {
      if (updatedParams[param] === undefined || updatedParams[param] === null || updatedParams[param] === "") {
        searchParams.delete(param)
      } else {
        searchParams.set(param, updatedParams[param])
      }
    }
  }

  // Reconstruct the URL with the updated search parameters
  url.search = searchParams.toString()

  return url.toString()
}
