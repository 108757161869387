import { useSeoFaqScript, useSeoMedicalTherapyScript, useSeoProductScript } from "@/hooks/seo-schema/useSeoHooks"
import { PageStoryblok } from "@/types/component-types"
import { BlokProps } from "../StoryblokHelpers"

interface VaccinationPageScriptsProps {
  blok: BlokProps<PageStoryblok>["blok"]
}

export const VaccinationPageScripts = ({ blok }: VaccinationPageScriptsProps) => {
  const ldFaqScript = useSeoFaqScript(blok)
  const ldMedicalTherapyScript = useSeoMedicalTherapyScript(blok)
  const ldProductScript = useSeoProductScript(blok)

  return (
    <>
      {ldFaqScript && (
        <script
          id="vaccinationFaqJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldFaqScript)
          }}
        />
      )}
      {ldMedicalTherapyScript && (
        <script
          id="vaccinationMedicalTherapyJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldMedicalTherapyScript)
          }}
        />
      )}
      {ldProductScript && (
        <script
          id="vaccinationProductJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldProductScript)
          }}
        />
      )}
    </>
  )
}
