import { fetchDataWithPagination } from "@/lib/fetchDataWithPagination"
import {
  ArticleTagStory,
  ClinicFilterProps,
  ClinicItemType,
  FactAdviceListItem,
  FilterTagStory,
  RegionsItem,
  VaccinationLocation
} from "@/types/NonSyncedTypes"
import { VaccinationBusLocationPageStoryblok } from "@/types/component-types"
import { sortObjectsAlphabetically } from "@/utils/utils"
import { defaultSbParams } from "@lib/defaultSbParams"
import { ISbStories, ISbStoriesParams, ISbStory, ISbStoryData } from "@storyblok/react/rsc"
import { addDays, format } from "date-fns"
import StoryblokClient from "storyblok-js-client"

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  cache: { type: "memory", clear: "auto" }
})

const getStory = async <T>(slug: string) => {
  try {
    const isProduction = process.env.DOKTOR_ENVIRONMENT === "production"

    const sbParams: ISbStoriesParams = {
      version: isProduction ? "published" : "draft",
      from_release: isProduction ? process.env.STORYBLOK_CONTENT_PIPELINE : undefined,
      resolve_links: "url"
    }

    const { data } = (await Storyblok.get(`cdn/stories/${slug}`, sbParams, {
      cache: isProduction ? "default" : "no-store"
    })) as ISbStory

    return {
      story: data?.story as ISbStoryData<T>
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.status === 404) {
      return {
        story: null
      }
    } else {
      throw err
    }
  }
}

const getClinicTags = async (starts_with: string | undefined) => {
  const sbParams: ISbStoriesParams = {
    ...defaultSbParams,
    resolve_relations: "category_story",
    starts_with: starts_with,
    per_page: 100
  }

  const { data: tags } = (await Storyblok.get(`cdn/stories`, sbParams)) as ISbStories

  const clinicTags = tags.stories.map(tag => {
    const tagName = tag.content.category_nicename ?? tag.content.region_nicename ?? tag.name
    return {
      id: tag.uuid,
      slug: tag.slug,
      name: tagName,
      order: tag.content.order,
      icon: tag.content.category_icon || undefined,
      tagStory: tag.content.category_story ? tags.rels.find(rel => rel.uuid === tag.content.category_story) : undefined,
      displayCollabodocLink: tag.content.display_collabodoc_link
    }
  }) as FilterTagStory[]

  return {
    tags: clinicTags
  }
}

const getClinicRegionTags = async (starts_with: string | undefined) => {
  const sbParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: starts_with,
    per_page: 100
  }

  const { data: tags } = (await Storyblok.get(`cdn/stories`, sbParams)) as ISbStories

  const clinicTags = tags.stories.map(tag => {
    const tagName = tag.content.category_nicename ?? tag.content.region_nicename ?? tag.name

    return {
      id: tag.uuid,
      slug: tag.slug,
      name: tagName,
      order: tag.content.order,
      icon: tag.content.category_icon || undefined,
      displayCollabodocLink: tag.content.display_collabodoc_link
    }
  }) as FilterTagStory[]

  return {
    tags: clinicTags
  }
}

const getClinics = async (filters: ClinicFilterProps, regionParam = "", serviceParam = "") => {
  const sbParams: ISbStoriesParams = {
    ...defaultSbParams,
    by_slugs: "vardcentraler/*/config",
    per_page: 100,
    filter_query: {
      region_tags_story: {
        any_in_array: regionParam ? regionParam : null
      },
      clinic_tags_story: {
        any_in_array: serviceParam ? serviceParam : null
      }
    }
  }

  const { data } = (await Storyblok.get(`cdn/stories`, sbParams)) as ISbStories
  const { tags: clinicTags } = await getClinicTags("data/clinictags")
  const { tags: regionTags } = await getClinicRegionTags("data/regioner")

  const returnedClinicTags: FilterTagStory[] = []
  const returnedRegionTags: FilterTagStory[] = []

  const clinics = data.stories.map(clinic => {
    const storyClinicTags: FilterTagStory[] = []
    const storyRegionTags: FilterTagStory[] = []

    clinic.content.clinic_tags_story.map((tag: string) => {
      const matchedTag = clinicTags.find(item => item.id === tag)
      if (matchedTag) {
        storyClinicTags.push(matchedTag)
        if (!returnedClinicTags.includes(matchedTag)) returnedClinicTags.push(matchedTag)
      }
    })

    clinic.content.region_tags_story.map((tag: string) => {
      const matchedTag = regionTags.find(item => item.id === tag)
      if (matchedTag) {
        storyRegionTags.push(matchedTag)
        if (!returnedRegionTags.includes(matchedTag)) returnedRegionTags.push(matchedTag)
      }
    })

    // Filter out not matching clinics by region
    if (filters.regionTag !== null) {
      const matchedRegionStoryTag = storyRegionTags.find(item => item.slug === filters.regionTag)
      if (!matchedRegionStoryTag) return undefined
    }

    // Filter out not matching clinics by clinic tag
    if (filters.clinicTag !== null && filters.clinicTag !== "") {
      const matchedClinicStoryTag = storyClinicTags.find(
        item => item.slug === filters.clinicTag || item.id === filters.clinicTag
      )
      if (!matchedClinicStoryTag) return undefined
    }

    return {
      id: clinic.id,
      clinic_name: clinic.content.clinic_name,
      slug: clinic.content.link.cached_url,
      clinic_tags: storyClinicTags,
      region_tags: storyRegionTags,
      address: clinic.content.address,
      latitude: parseFloat(clinic.content.latitude),
      longitude: parseFloat(clinic.content.longitude),
      location_url: clinic.content.location_url.cached_url,
      clinic_api_id: clinic.content.clinic_api_id || null,
      list_me_url: clinic.content?.list_me_link?.cached_url || "",
      clinic_hsa_id: clinic.content.clinic_hsa_id,
      clinic_region_id: clinic.content.clinic_region_id,
      collabodoc_url: clinic.content.collabodoc_url || "",
      clinic_description_button: clinic.content.clinic_description_button?.[0] ?? undefined,
      list_button: clinic.content.list_button?.[0] ?? undefined
    }
  }) as ClinicItemType[]

  const returnedClinics = clinics.filter(clinic => clinic !== undefined)

  // Filter out clinic tags and region tags with 0 clinics
  const filteredClinicTags = returnedClinicTags.filter(tag =>
    returnedClinics.some(clinic => clinic.clinic_tags.includes(tag))
  )
  const filteredRegionTags = returnedRegionTags.filter(tag =>
    returnedClinics.some(clinic => clinic.region_tags.includes(tag))
  )

  return {
    clinics: sortObjectsAlphabetically(returnedClinics, "clinic_name"),
    clinicTags: sortObjectsAlphabetically(filteredClinicTags, "order"),
    regionTags: sortObjectsAlphabetically(filteredRegionTags, "name")
  }
}

const getRegionTags = async (starts_with: string | undefined) => {
  const sbParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: starts_with,
    per_page: 100
  }

  const { data: tags } = (await Storyblok.get(`cdn/stories`, sbParams)) as ISbStories

  const clinicTags = tags.stories.map(tag => {
    const tagName = tag.content.category_nicename ?? tag.content.region_nicename ?? tag.name

    return {
      id: tag.uuid,
      slug: tag.slug,
      name: tagName,
      icon: tag.content.category_icon || undefined
    }
  }) as FilterTagStory[]

  return {
    tags: clinicTags
  }
}

const getVaccinationLocations = async (filter = "") => {
  try {
    const slug = "vaccinationsplats/"

    const customParams: ISbStoriesParams = {
      ...defaultSbParams,
      starts_with: slug,
      excluding_slugs: slug,
      is_startpage: false,
      per_page: 50,
      filter_query: {
        region: {
          in: filter ? filter : null
        }
      }
    }

    const { tags: regionTags } = await getClinicTags("data/regioner")
    const locations = await fetchDataWithPagination<ISbStoryData<VaccinationBusLocationPageStoryblok>[]>({
      customParams
    })

    const returnedRegionTags: FilterTagStory[] = []

    const result = locations.map(story => {
      if (!story.content) return null
      const storyRegionTags: FilterTagStory[] = []

      const matchedTag = regionTags.find(item => item.id === story.content.region)
      if (matchedTag) {
        storyRegionTags.push(matchedTag)
        if (!returnedRegionTags.includes(matchedTag)) returnedRegionTags.push(matchedTag)
      }

      return {
        uuid: story.id.toString(),
        id: story.id.toString(),
        name: story.name,
        url: story.full_slug,
        region: storyRegionTags,
        locations: story.content.locations
          ? story.content.locations
              .filter(location => location.latitude && location.longitude)
              .map(location => {
                if (location.latitude && location.longitude) {
                  return {
                    id: story.id,
                    name: story.name,
                    url: story.full_slug,
                    address: location.title,
                    center: {
                      lat: parseFloat(location.latitude),
                      lng: parseFloat(location.longitude)
                    }
                  }
                }
              })
              .filter(location => location !== null)
          : []
      }
    }) as VaccinationLocation[]

    return {
      locations: sortObjectsAlphabetically(result, "name"),
      regionTags: sortObjectsAlphabetically(regionTags, "name")
    }
  } catch (err) {
    console.error("Failed fetching: ", err)
    throw err
  }
}

const getArticleTags = async () => {
  const sbParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: "data/categories",
    per_page: 100
  }

  const { data: tags } = (await Storyblok.get(`cdn/stories`, sbParams)) as ISbStories

  const articleTags = tags.stories.map(tag => {
    const tagName = tag.content.category_nicename ?? tag.content.region_nicename ?? tag.name

    return {
      id: tag.uuid,
      slug: tag.slug,
      name: tagName
    }
  }) as ArticleTagStory[]

  return {
    tags: sortObjectsAlphabetically(articleTags, "name")
  }
}

const getAuthorsById = async (ids: string[]) => {
  const customParams: ISbStoriesParams = {
    ...defaultSbParams,
    by_uuids: ids.join(",")
  }

  const { data: authors } = (await Storyblok.get(`cdn/stories`, customParams)) as ISbStories

  return {
    authors: authors?.stories as ISbStoryData[]
  }
}

const getArticles = async (slug: string | undefined, page?: string, perPage?: number, filter = "", author = "") => {
  const articlesPerPage = perPage ? perPage : 6
  const pageNumber = page ? parseInt(page) : 1
  const tomorrowDate = addDays(new Date(), 1)
  const tomorrow = format(tomorrowDate, "yyyy-MM-dd")

  const customParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: slug,
    excluding_slugs: slug,
    sort_by: "content.published_date:desc",
    is_startpage: false,
    per_page: articlesPerPage,
    page: pageNumber,
    filter_query: {
      article_tags: {
        any_in_array: filter ? filter : null
      },
      author: {
        like: author ? author : null
      },
      published_date: {
        lt_date: tomorrow
      }
    }
  }

  const { data: articles, total } = (await Storyblok.get(`cdn/stories`, customParams)) as ISbStories
  const totalPages = Math.ceil(total / articlesPerPage)

  return {
    articles: articles?.stories as ISbStoryData[],
    currentPage: pageNumber,
    totalPages: totalPages
  }
}

const searchFactAdvice = async (phrase: string, abortController: AbortController) => {
  try {
    const slug = "fakta-rad/"

    const { data, total } = await Storyblok.get(
      `cdn/stories`,
      {
        ...defaultSbParams,
        search_term: phrase,
        starts_with: slug,
        excluding_slugs: slug,
        page: 1,
        per_page: 10
      },
      { signal: abortController.signal }
    )
    return {
      stories: (data.stories as ISbStoryData[]).map(story => ({
        id: story.id,
        name: story.name,
        url: story.full_slug,
        start_page: story.is_startpage,
        parent_id: story.parent_id,
        header_icon: story.content.header_icon ?? null
      })) as FactAdviceListItem[],
      total
    }
  } catch (err) {
    console.error("Failed fetching: ", err)
    throw err
  }
}

const getFactAdvice = async () => {
  try {
    const slug = "fakta-rad/"

    const customParams: ISbStoriesParams = {
      ...defaultSbParams,
      starts_with: slug,
      is_startpage: true,
      excluding_slugs: slug,
      per_page: 50,
      excluding_fields: "content"
    }

    let result = await fetchDataWithPagination({ customParams })

    // Sort top-level items by name
    result = sortObjectsAlphabetically(result, "name")

    const fetchFactAdvicePages = (await Promise.all(
      result.map(async story => {
        const customParams: ISbStoriesParams = {
          ...defaultSbParams,
          starts_with: story.full_slug,
          is_startpage: false,
          excluding_slugs: slug,
          per_page: 50
        }

        const fetchFactAdviceSubItems = await fetchDataWithPagination({ customParams })
        const sortedSubItems = sortObjectsAlphabetically(fetchFactAdviceSubItems, "name")

        const subItems = await Promise.all(
          sortedSubItems.map(async subStory => ({
            id: subStory.id,
            name: subStory.name,
            url: subStory.full_slug,
            start_page: subStory.is_startpage,
            parent_id: subStory.parent_id,
            header_icon: subStory.content.header_icon ?? null
          }))
        )

        return {
          id: story.id,
          name: story.name,
          url: story.full_slug,
          start_page: story.is_startpage,
          parent_id: story.parent_id,
          header_icon: story.content.header_icon ?? null,
          children: subItems
        }
      })
    )) as FactAdviceListItem[]

    return {
      stories: fetchFactAdvicePages
    }
  } catch (err) {
    console.error("Failed fetching: ", err)
    throw err
  }
}

const getRegions = async () => {
  const customParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: "data/regioner/"
  }

  const fetchRegions = fetchDataWithPagination({
    customParams
  }).then(result => {
    const regionsData = result.map(region => {
      return {
        id: region.id,
        name: region.name,
        content: region.content
      }
    })
    return regionsData
  }) as Promise<RegionsItem[]>

  const regions = await fetchRegions

  return {
    regions: sortObjectsAlphabetically(regions, "name")
  }
}

const getInitialPressreleases = async () => {
  const slug = "press/"

  const sbParams: ISbStoriesParams = {
    ...defaultSbParams
  }

  const { data: page } = (await Storyblok.get(`cdn/stories/${slug}`, sbParams)) as ISbStory

  const customParams: ISbStoriesParams = {
    ...defaultSbParams,
    starts_with: slug,
    excluding_slugs: slug,
    sort_by: "content.published_date:desc",
    per_page: 4,
    page: 1,
    cv: page.cv
  }

  const pressreleases = (await Storyblok.get(`cdn/stories`, customParams)) as ISbStories

  return {
    stories: pressreleases,
    cacheVersion: page.cv
  }
}

export {
  getArticles,
  getArticleTags,
  getAuthorsById,
  getClinics,
  getFactAdvice,
  getInitialPressreleases,
  getRegions,
  getRegionTags,
  getStory,
  getVaccinationLocations,
  searchFactAdvice
}
