"use client"

import CheckCircle from "@layout/icons/CheckCircle"
import ErrorIcon from "@layout/icons/ErrorIcon"
import * as Dialog from "@radix-ui/react-dialog"
import { Paragraph } from "@ui-library/typography/Body"
import { H3 } from "@ui-library/typography/Headings"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import { useEffect, useState } from "react"

interface DialogProps {
  title: string
  message: string
  open: boolean
  onClose: () => void
  error?: boolean
}

export const ConfirmDialog = ({ title, message, open, onClose, error = false }: DialogProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(open)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleClose = () => {
    onClose()
    setIsOpen(false)
  }

  if (!isMounted) return null

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[9999] bg-black/20" />
        <Dialog.Content className="fixed left-2/4 top-2/4 z-[99998] flex max-h-[85vh] w-[90vw] max-w-sm -translate-x-2/4 -translate-y-2/4 flex-col items-center rounded-xl bg-surface pt-8 text-center">
          {error ? <ErrorIcon /> : <CheckCircle />}
          <Dialog.Title asChild className="mt-2 px-8">
            <H3>{title}</H3>
          </Dialog.Title>
          <Dialog.Description asChild className="px-8">
            <Paragraph size="small">{message}</Paragraph>
          </Dialog.Description>
          <Dialog.Close
            className={cn(
              "relative z-[99999] mt-8 w-full cursor-pointer rounded-b-xl bg-primary-50 p-4 text-on-primary-50",
              error && "bg-warning-50"
            )}
          >
            <Label size="medium" className="pointer-events-none">
              Ok
            </Label>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ConfirmDialog
