const fetchUsps = async (clinicId: string, lang = "sv-se") => {
  try {
    if (!clinicId) return { status: "Ok", usps: null }

    const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/v1/clinic/${clinicId}/webinfo`, {
      method: "GET",
      headers: {
        "Accept-Language": lang
      },
      next: { revalidate: 86400 }
    })

    if (response.status !== 200) {
      return { status: "Not ok", usps: null }
    }

    const usps = await response.json()

    return {
      status: "Ok",
      usps
    }
  } catch (err) {
    return {
      status: err,
      usps: null
    }
  }
}

export { fetchUsps }
