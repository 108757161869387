"use client"

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import Link from "next/link"

import ArrowForwardIcon from "@layout/icons/ArrowForwardIcon"
import CloseIcon from "@layout/icons/CloseIcon"
import SearchIcon from "@layout/icons/SearchIcon"

import { Label } from "@/components/ui-library/typography/Labels"
import { useOnClickOutside } from "@/hooks/useOnClickOutside"
import { searchFactAdvice } from "@/storyblok/stories"
import { FactAdviceListItem } from "@/types/NonSyncedTypes"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import debounce from "lodash/debounce"
import { ImSpinner } from "react-icons/im"

interface SearchBlockProps {
  placeholder?: string
}

const SearchBlock = ({ placeholder }: SearchBlockProps) => {
  const [stories, setStories] = useState<FactAdviceListItem[]>([])
  const [total, setTotal] = useState<number>(0)
  const [text, setText] = useState<string>("")
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [showResults, setShowResults] = useState<boolean>(false)
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const resultRefs = useRef<(HTMLAnchorElement | null)[]>([])
  const abortControllerRef = useRef<AbortController | null>(null)

  const findStories = async (phrase: string) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    const controller = new AbortController()
    abortControllerRef.current = controller

    try {
      setIsFetching(true)
      const { stories: factActiveStories, total } = await searchFactAdvice(phrase, controller)
      setTotal(total)
      setStories(factActiveStories)
      setIsFetching(false)

      if (factActiveStories.length > 0) {
        setShowResults(true)
      } else {
        setShowResults(false)
      }
    } catch (err) {
      if ((err as Error).name !== "AbortError") {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    if (selectedResultIndex >= 0) {
      resultRefs.current[selectedResultIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      })
    }
  }, [selectedResultIndex])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchResults = useCallback(debounce(findStories, 300), [])

  const clearInputField = useCallback(() => {
    debouncedFetchResults.cancel()
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    setTotal(0)
    setStories([])
    setIsFetching(false)
    setText("")
    setSelectedResultIndex(-1)
  }, [debouncedFetchResults])

  useEffect(() => {
    if (text === "") {
      clearInputField()
      return
    }

    debouncedFetchResults(text)
  }, [clearInputField, debouncedFetchResults, text])

  const handleFocus = () => {
    if (text.length > 0) {
      setShowResults(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault()
      setSelectedResultIndex(prevIndex => (prevIndex < stories.length - 1 ? prevIndex + 1 : prevIndex))
    } else if (e.key === "ArrowUp") {
      e.preventDefault()
      setSelectedResultIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    } else if (e.key === "Enter") {
      if (selectedResultIndex >= 0) {
        resultRefs.current[selectedResultIndex]?.click()
      }
    }
  }

  useOnClickOutside(wrapperRef, () => {
    setShowResults(false)
    setSelectedResultIndex(-1)
    inputRef.current?.blur()
  })

  const renderedResults = useMemo(() => {
    return (
      <div className="absolute left-0 top-0 z-10 flex h-auto max-h-[480px] w-full flex-col justify-between rounded-lg bg-background shadow-elevation-1">
        <Label size="xsmall" asChild>
          <span className="mb-14 block w-full pb-2 pl-6 pt-1 text-left">{total} Träffar</span>
        </Label>

        <div className="overflow-y-auto">
          <div className="h-auto max-h-[380px] pl-14 pr-6">
            {stories.map((item, index) => (
              <Link
                key={item.id}
                href={`/${item.url}`}
                ref={(el: HTMLAnchorElement | null) => {
                  if (el) resultRefs.current[index] = el
                }}
                className={cn("flex w-full flex-wrap items-center justify-between rounded-md px-2 py-2 text-left", {
                  "bg-neutral-10": index === selectedResultIndex
                })}
              >
                <Paragraph size="medium" asChild className="w-4/5 cursor-pointer">
                  <span>{item.name}</span>
                </Paragraph>
                <ArrowForwardIcon className="text-primary-50" />
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }, [total, stories, selectedResultIndex])

  return (
    <div
      ref={wrapperRef}
      className={cn("relative mx-auto -mt-8 w-full max-w-screen-md px-6 pt-6", {
        "": showResults && stories.length > 0
      })}
    >
      <div className="relative z-20 flex h-12 w-full items-center justify-between text-on-background">
        <SearchIcon className="absolute ml-4" />

        <input
          type="text"
          ref={inputRef}
          value={text}
          onChange={e => setText(e.target.value.trimStart())}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          placeholder={placeholder}
          className="w-full rounded-lg border border-neutral-30 bg-background py-3 pl-12 pr-3 text-on-background outline-none"
        />

        <div className="absolute right-4 m-0 flex items-center justify-center border-none bg-none">
          {isFetching && <ImSpinner size={24} className="m-2 animate-spin" />}
          {text && (
            <button
              onClick={() => {
                clearInputField()
              }}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>

      {showResults && stories.length > 0 && renderedResults}
    </div>
  )
}

export default SearchBlock
