import { ClinicItemType, FilterTagStory } from "@/types/NonSyncedTypes"
import { ClinicOverviewPageStoryblok } from "@/types/component-types"
import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { StoryblokComponent } from "@storyblok/react/rsc"
import PageContainer from "@ui-library/layout/PageContainer"

interface ClinicsOverviewProps {
  blok: BlokProps<ClinicOverviewPageStoryblok>["blok"]
  clinics: ClinicItemType[]
  regionTags: FilterTagStory[]
  clinicTags: FilterTagStory[]
}

export const ClinicOverviewPage = ({ blok, clinics, regionTags, clinicTags }: ClinicsOverviewProps) => {
  return (
    <>
      <PageContainer background={blok.background} {...withStoryblokEditable(blok)}>
        {blok.body?.map(nestedBlok => (
          <StoryblokComponent
            blok={nestedBlok}
            clinics={clinics}
            regionTags={regionTags}
            clinicTags={clinicTags}
            key={nestedBlok._uid}
          />
        ))}
      </PageContainer>
    </>
  )
}

export default ClinicOverviewPage
