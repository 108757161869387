const OpenNewTabIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 18C1.95 18 1.479 17.8043 1.087 17.413C0.695667 17.021 0.5 16.55 0.5 16V2C0.5 1.45 0.695667 0.979 1.087 0.587C1.479 0.195667 1.95 0 2.5 0H8.5C8.78333 0 9.021 0.0956666 9.213 0.287C9.40433 0.479 9.5 0.716667 9.5 1C9.5 1.28333 9.40433 1.52067 9.213 1.712C9.021 1.904 8.78333 2 8.5 2H2.5V16H16.5V10C16.5 9.71667 16.596 9.479 16.788 9.287C16.9793 9.09567 17.2167 9 17.5 9C17.7833 9 18.0207 9.09567 18.212 9.287C18.404 9.479 18.5 9.71667 18.5 10V16C18.5 16.55 18.3043 17.021 17.913 17.413C17.521 17.8043 17.05 18 16.5 18H2.5ZM6.5 12C6.31667 11.8167 6.225 11.5833 6.225 11.3C6.225 11.0167 6.31667 10.7833 6.5 10.6L15.1 2H12.5C12.2167 2 11.9793 1.904 11.788 1.712C11.596 1.52067 11.5 1.28333 11.5 1C11.5 0.716667 11.596 0.479 11.788 0.287C11.9793 0.0956666 12.2167 0 12.5 0H17.5C17.7833 0 18.0207 0.0956666 18.212 0.287C18.404 0.479 18.5 0.716667 18.5 1V6C18.5 6.28333 18.404 6.52067 18.212 6.712C18.0207 6.904 17.7833 7 17.5 7C17.2167 7 16.9793 6.904 16.788 6.712C16.596 6.52067 16.5 6.28333 16.5 6V3.4L7.875 12.025C7.69167 12.2083 7.46667 12.3 7.2 12.3C6.93333 12.3 6.7 12.2 6.5 12Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default OpenNewTabIcon
