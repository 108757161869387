"use client"

import Skeleton from "@/components/ui-library/skeleton/Skeleton"
import { useConfigStore } from "@/store/config-store"
import { ClinicItemType, FilterTagStory } from "@/types/NonSyncedTypes"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicListStoryblok } from "@storyblok-types"
import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Richtext from "@ui-library/richtext/Richtext"
import dynamic from "next/dynamic"
import { useSearchParams } from "next/navigation"
import { useState } from "react"
import { useMediaQuery } from "usehooks-ts"
import ClinicItem from "./ClinicItem"

const ClinicMap = dynamic(() => import("./ClinicMap"), {
  ssr: false,
  loading: () => (
    <Skeleton className="m-0 w-full overflow-hidden p-0 max-lg:hidden max-lg:h-80 max-lg:rounded-b-2xl max-lg:rounded-t-2xl lg:absolute lg:bottom-0 lg:right-0 lg:top-0 lg:max-w-8 lg:rounded-l-none lg:rounded-r-2xl" />
  )
})

const ClinicsFilters = dynamic(() => import("./ClinicsFilters"), {
  ssr: false,
  loading: () => (
    <div className="w-full p-8 lg:max-w-4 lg:p-10 xl:p-20">
      <Skeleton className="mb-4 h-[52px] rounded-lg lg:mb-8" />
      <Skeleton className="h-[52px] rounded-lg" />
    </div>
  )
})

const ClinicListDisplayToggle = dynamic(() => import("./ClinicListDisplayToggle"), {
  ssr: false,
  loading: () => (
    <div className="w-full px-8 pb-8 lg:hidden lg:max-w-4 lg:p-10 xl:p-20">
      <Skeleton className="h-[52px] rounded-lg" />
    </div>
  )
})

interface ClinicsListProps {
  blok: BlokProps<ClinicListStoryblok>["blok"]
  clinics: ClinicItemType[]
  regionTags: FilterTagStory[]
  clinicTags: FilterTagStory[]
}

export const ClinicsList = ({ blok, clinics, regionTags, clinicTags }: ClinicsListProps) => {
  const { strings } = useConfigStore.getState()
  const searchParams = useSearchParams()
  const serviceFilter = searchParams.get("service")
  const isFilterWithDieplayCollabodocLink =
    clinicTags.find(({ id }) => serviceFilter === id)?.displayCollabodocLink ?? false
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [method, setMethod] = useState(isMobile)

  const handleDisplayClick = (active: boolean) => {
    setMethod(active)
  }

  return (
    <>
      <GridWrapper {...storyblokEditable(blok as SbBlokData)} className="my-8">
        <Row justifyContent="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
            <article className="text-center">
              <Richtext content={blok.intro_content} hero />

              {blok.buttons && blok.buttons.length > 0 && (
                <div className="my-12 w-full">
                  {blok.buttons.map(button => (
                    <StoryblokComponent key={button._uid} blok={button} />
                  ))}
                </div>
              )}
            </article>
          </Col>
        </Row>
      </GridWrapper>

      <GridWrapper>
        <div className="md:mx-4">
          <section className="relative mx-auto mb-4 flex w-full max-w-footer flex-wrap items-center overflow-hidden rounded-2xl bg-surface shadow-elevation-1 lg:mb-8 lg:min-h-[360px]">
            {regionTags.length >= 1 && (
              <ClinicsFilters regionTags={regionTags} clinicTags={blok.clinic_tag_filter ? null : clinicTags} />
            )}
            <ClinicListDisplayToggle active={method} onClick={handleDisplayClick} />
            {clinics && clinics.length > 0 && <ClinicMap markers={clinics} className={method ? "hidden" : "block"} />}
          </section>
        </div>

        {clinics && clinics.length > 0 ? (
          <Row>
            {clinics.map(story => {
              return (
                <Col grid={{ xs: 12, lg: 6 }} key={story.id} className="my-4">
                  <ClinicItem
                    story={story}
                    useCollobadoc={blok.use_collabodoc_link || isFilterWithDieplayCollabodocLink}
                  />
                </Col>
              )
            })}
          </Row>
        ) : (
          <Row justifyContent="center">
            <Col grid={{ xs: 12, lg: 6 }} className="text-center">
              <h3>{strings?.clinics.notFound}</h3>
            </Col>
          </Row>
        )}
      </GridWrapper>
    </>
  )
}

export default ClinicsList
