import React from "react"

import { cn } from "@utils/utils"

export const UnorderedList = ({
  children,
  className
}: {
  children: JSX.Element | React.ReactNode
  className?: string
}) => {
  return <ul className={cn("mb-6 ml-8 mr-0 mt-4 list-disc", className)}>{children}</ul>
}

export default UnorderedList
