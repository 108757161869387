"use client"

import { defaultSbParams } from "@/lib/defaultSbParams"
import { ISbStories, ISbStoriesParams, ISbStoryData, getStoryblokApi } from "@storyblok/react/rsc"
import { Button } from "@ui-library/button/Button"
import PressreleaseCard from "@ui-library/card/PressreleaseCard"
import Col from "@ui-library/grid/Col"
import { useCallback, useState } from "react"

interface PressreleasesListProps {
  initialPressreleases: ISbStories
  cacheVersion?: number
}

export const PressreleasesList = ({ initialPressreleases, cacheVersion }: PressreleasesListProps) => {
  const pressreleasesPerPage = 4
  const [pressreleases, setPressreleases] = useState<ISbStoryData[]>(initialPressreleases.data.stories)
  const [currentPage, setCurrentPage] = useState<number>(2)
  const [currentPressreleasesCount, setcurrentPressreleasesCount] = useState<number>(pressreleasesPerPage)
  const [maxPressreleasesCount, setMaxPressreleasesCount] = useState<number>(initialPressreleases.total)
  const [loading, setLoading] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(initialPressreleases.data.stories.length < maxPressreleasesCount)

  const asyncSetArticles = useCallback(async () => {
    setLoading(true)
    const sbParams: ISbStoriesParams = {
      ...defaultSbParams,
      starts_with: "press/",
      sort_by: "content.published_date:desc",
      per_page: pressreleasesPerPage,
      page: currentPage,
      is_startpage: false,
      cv: cacheVersion
    }

    const storyblokApi = getStoryblokApi()
    const { data, total } = (await storyblokApi.get(`cdn/stories`, sbParams)) as ISbStories

    setMaxPressreleasesCount(total)
    setCurrentPage(currentPage + 1)
    setPressreleases([...pressreleases, ...data.stories])
    setcurrentPressreleasesCount(currentPressreleasesCount + data.stories.length)
  }, [currentPage, pressreleases, currentPressreleasesCount, cacheVersion])

  const handleLoadMore = () => {
    asyncSetArticles()
    if (currentPressreleasesCount >= maxPressreleasesCount) setShowMore(false)
    setLoading(false)
  }

  return (
    <>
      {pressreleases.map(pressrelease => (
        <Col grid={{ xs: 12, lg: 6 }} key={pressrelease.id} className="my-4">
          <PressreleaseCard pressrelease={pressrelease} />
        </Col>
      ))}

      {showMore && (
        <Col className="mt-12 text-center">
          <Button variant="primary_outlined" disabled={loading} onClick={handleLoadMore}>
            Visa fler
          </Button>
        </Col>
      )}
    </>
  )
}

export default PressreleasesList
