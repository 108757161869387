import { StoryblokComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { OfferingStoryblok } from "@storyblok-types"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"

export const Offering = ({ blok }: BlokProps<OfferingStoryblok>) => {
  return (
    <GridWrapper background={blok.background_color} {...withStoryblokEditable(blok)}>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
          <h2>{blok.title}</h2>
          {blok.description ?? <Paragraph size="medium">{blok.description}</Paragraph>}

          {blok.chips && (
            <div className="mt-6 flex flex-wrap justify-start gap-2 lg:mt-10 lg:justify-center">
              {blok.chips?.map(chip => <StoryblokComponent key={chip._uid} blok={chip} />)}
            </div>
          )}

          {blok.buttons && (
            <div className="mt-6 flex flex-wrap justify-start gap-2 lg:mt-10 lg:justify-center">
              {blok.buttons?.map(button => <StoryblokComponent key={button._uid} blok={button} />)}
            </div>
          )}
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default Offering
