import { TableStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"

const Table = ({ data }: { data: TableStoryblok }) => {
  return (
    <table className="mx-auto my-8 w-full max-w-[30rem] text-left">
      <thead>
        <tr>
          {data.thead.map(th => (
            <th className="pb-3" key={th._uid}>
              <Label size="medium">{th.value}</Label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.tbody.map(row => (
          <tr key={row._uid}>
            {row.body.map(cell => (
              <td key={cell._uid}>
                <Paragraph size="small">{cell.value}</Paragraph>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
