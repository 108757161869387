"use client"

import { Label } from "@/components/ui-library/typography/Labels"
import { scrollToElement } from "@/helpers/ScrollToElement"
import { useWindowScroll } from "@/hooks/useWindowScroll"
import { useConfigStore } from "@/store/config-store"
import ArrowForwardIcon from "@layout/icons/ArrowForwardIcon"
import { CtaSectionStoryblok, FactSectionStoryblok, ImageSectionStoryblok } from "@storyblok-types"
import { cn } from "@utils/utils"
import { useEffect, useRef, useState } from "react"

type SideNavigationProps = {
  items?: (FactSectionStoryblok | ImageSectionStoryblok | CtaSectionStoryblok)[]
}

export const SideNavigation = ({ items }: SideNavigationProps) => {
  const strings = useConfigStore.getState().strings
  const extraOffset: number = 112
  const extraArrowOffset: number = 6
  const [activeItem, setActiveItem] = useState<string>("")
  const [iconTopOffset, setIconTopOffset] = useState<number | null>(extraArrowOffset)
  const activeNavItemRef = useRef<HTMLSpanElement | null>(null)
  const ulRef = useRef<HTMLUListElement | null>(null)

  const handleScroll = () => {
    const sections = document.querySelectorAll('[id^="section-"]')
    let currentActive = ""

    sections.forEach(section => {
      const rect = section.getBoundingClientRect()
      if (rect.top <= extraOffset) {
        currentActive = section.id
      }
    })

    setActiveItem(currentActive)
  }

  useWindowScroll(handleScroll)

  useEffect(() => {
    if (activeNavItemRef.current && activeItem && ulRef.current) {
      const rect = activeNavItemRef.current.getBoundingClientRect()
      const ulRect = ulRef.current.getBoundingClientRect()
      const offset = rect.top - ulRect.top
      setIconTopOffset(offset + extraArrowOffset)
    }
  }, [activeItem, ulRef])

  if (!items) return null
  return (
    <aside className="rounded-2xl bg-background p-8">
      <h3>{strings?.fact_advice.content}</h3>
      <ul className="relative lg:pl-8" ref={ulRef}>
        {items.map((item, i) => {
          const title = item.title as string
          const target = `section-${item._uid}`

          return (
            <li key={item._uid} className="mb-2">
              <Label size="medium" asChild>
                <span
                  ref={activeItem === target ? activeNavItemRef : null}
                  className={cn(
                    "cursor-pointer text-on-background transition-colors lg:text-neutral-50 lg:hover:text-on-background",
                    !activeItem && i === 0 && "lg:text-on-background",
                    activeItem === target && "lg:text-on-background"
                  )}
                  onClick={() => scrollToElement(target)}
                >
                  {title}
                </span>
              </Label>
            </li>
          )
        })}

        <ArrowForwardIcon
          className="absolute left-0 hidden transition-all lg:block"
          style={{ top: `${iconTopOffset}px` }}
        />
      </ul>
    </aside>
  )
}

export default SideNavigation
