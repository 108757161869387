const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5 22.5C15.75 22.5 15.9517 22.4239 16.105 22.2717C16.2572 22.1183 16.3333 21.9167 16.3333 21.6667V14.125C16.3333 13.9028 16.25 13.715 16.0833 13.5617C15.9167 13.4094 15.7222 13.3333 15.5 13.3333C15.25 13.3333 15.0483 13.4167 14.895 13.5833C14.7428 13.75 14.6667 13.9444 14.6667 14.1667V21.7083C14.6667 21.9306 14.75 22.1183 14.9167 22.2717C15.0833 22.4239 15.2778 22.5 15.5 22.5ZM15.5 10.9583C15.7778 10.9583 16.0211 10.8611 16.23 10.6667C16.4378 10.4722 16.5417 10.2222 16.5417 9.91667C16.5417 9.63889 16.4378 9.40278 16.23 9.20833C16.0211 9.01389 15.7778 8.91667 15.5 8.91667C15.2222 8.91667 14.9789 9.01389 14.77 9.20833C14.5622 9.40278 14.4583 9.63889 14.4583 9.91667C14.4583 10.2222 14.5622 10.4722 14.77 10.6667C14.9789 10.8611 15.2222 10.9583 15.5 10.9583ZM15.5 30C13.4167 30 11.4583 29.6111 9.625 28.8333C7.79167 28.0556 6.20167 26.9933 4.855 25.6467C3.50722 24.2989 2.44444 22.7083 1.66667 20.875C0.888889 19.0417 0.5 17.0833 0.5 15C0.5 12.9167 0.888889 10.9583 1.66667 9.125C2.44444 7.29167 3.50722 5.70111 4.855 4.35333C6.20167 3.00667 7.79167 1.94444 9.625 1.16667C11.4583 0.388889 13.4167 0 15.5 0C17.5833 0 19.5417 0.388889 21.375 1.16667C23.2083 1.94444 24.7989 3.00667 26.1467 4.35333C27.4933 5.70111 28.5556 7.29167 29.3333 9.125C30.1111 10.9583 30.5 12.9167 30.5 15C30.5 17.0833 30.1111 19.0417 29.3333 20.875C28.5556 22.7083 27.4933 24.2989 26.1467 25.6467C24.7989 26.9933 23.2083 28.0556 21.375 28.8333C19.5417 29.6111 17.5833 30 15.5 30ZM15.5 28.3333C19.1944 28.3333 22.3406 27.035 24.9383 24.4383C27.535 21.8406 28.8333 18.6944 28.8333 15C28.8333 11.3056 27.535 8.15944 24.9383 5.56167C22.3406 2.965 19.1944 1.66667 15.5 1.66667C11.8056 1.66667 8.66 2.965 6.06333 5.56167C3.46556 8.15944 2.16667 11.3056 2.16667 15C2.16667 18.6944 3.46556 21.8406 6.06333 24.4383C8.66 27.035 11.8056 28.3333 15.5 28.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default InfoIcon
