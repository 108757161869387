const MapIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.0782 27.9519H1.44922C1.27564 27.9519 1.10205 27.894 0.986329 27.7204C0.870606 27.6047 0.870605 27.4311 0.870605 27.2575L3.18507 14.523C3.24293 14.2336 3.47438 14.0601 3.76369 14.0601H7.81652C8.04796 14.0601 8.27941 14.2336 8.33727 14.4651L8.80017 15.7959H13.6631C14.0102 15.7959 14.2417 16.0274 14.2417 16.3745C14.2417 16.7217 14.0102 16.9531 13.6631 16.9531H8.39513C8.16369 16.9531 7.93224 16.7796 7.87438 16.5481L7.41149 15.2173H4.22658L2.14356 26.7947H27.3838L25.3008 15.2173H22.1737L21.7109 16.5481C21.5951 16.7796 21.3637 16.9531 21.1322 16.9531H15.6907C15.3436 16.9531 15.1121 16.7217 15.1121 16.3745C15.1121 16.0274 15.3436 15.7959 15.6907 15.7959H20.7272L21.1901 14.4651C21.248 14.2336 21.4794 14.0601 21.7109 14.0601H25.7637C26.053 14.0601 26.2844 14.2336 26.3423 14.523L28.6568 27.2575C28.7146 27.4311 28.6568 27.6047 28.541 27.7204C28.4253 27.894 28.2517 27.9519 28.0782 27.9519Z"
        fill="currentColor"
      />
      <path
        d="M18.8153 23.8994H2.14356C1.79639 23.8994 1.56494 23.668 1.56494 23.3208C1.56494 22.9737 1.79639 22.7422 2.14356 22.7422H18.2366V21.0064C18.2366 20.6592 18.4681 20.4277 18.8153 20.4277H22.8681V18.6919C22.8681 18.3447 23.0995 18.1133 23.4467 18.1133H26.458C26.8052 18.1133 27.0366 18.3447 27.0366 18.6919C27.0366 19.0391 26.8052 19.2705 26.458 19.2705H24.0278V21.0064C24.0278 21.3535 23.7964 21.585 23.4492 21.585H19.3964V23.3208C19.3964 23.668 19.1649 23.8994 18.8178 23.8994H18.8153Z"
        fill="currentColor"
      />
      <path
        d="M14.6389 18.6288C14.4175 18.6288 14.249 18.5181 14.1383 18.3496C13.9723 17.9596 9.07666 9.28291 9.07666 6.39234C9.07666 3.33322 11.5798 0.830078 14.6389 0.830078C17.698 0.830078 20.2012 3.33322 20.2012 6.39234C20.2012 9.28542 15.3056 17.9622 15.1396 18.3496C15.0289 18.5156 14.8603 18.6288 14.6389 18.6288ZM14.6389 1.94454C12.1911 1.94454 10.1886 3.94706 10.1886 6.39486C10.1886 8.34203 13.0817 14.015 14.6389 16.9081C16.1962 14.015 19.0892 8.28668 19.0892 6.39486C19.0892 3.94706 17.0867 1.94454 14.6389 1.94454Z"
        fill="currentColor"
      />
      <path
        d="M14.6388 9.17449C13.0815 9.17449 11.8589 7.95185 11.8589 6.39462C11.8589 4.83739 13.0815 3.61475 14.6388 3.61475C16.196 3.61475 17.4186 4.83739 17.4186 6.39462C17.4186 7.95185 16.196 9.17449 14.6388 9.17449ZM14.6388 4.72418C13.6928 4.72418 12.9708 5.44619 12.9708 6.3921C12.9708 7.33802 13.6928 8.06003 14.6388 8.06003C15.5847 8.06003 16.3067 7.33802 16.3067 6.3921C16.3067 5.44619 15.5847 4.72418 14.6388 4.72418Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MapIcon
