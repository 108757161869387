import { getInitialPressreleases } from "@/storyblok/stories"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { PressreleasesListSectionStoryblok } from "@storyblok-types"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PressreleasesList from "./PressreleasesList"

export const PressreleasesListSection = async ({ blok }: BlokProps<PressreleasesListSectionStoryblok>) => {
  const { stories, cacheVersion } = await getInitialPressreleases()

  return (
    <GridWrapper background="surface" {...storyblokEditable(blok as SbBlokData)}>
      {blok.title && (
        <Row>
          <Col grid={{ xs: 12 }} className="text-left lg:text-center">
            <h2>{blok.title}</h2>
          </Col>
        </Row>
      )}
      <Row>
        <PressreleasesList initialPressreleases={stories} cacheVersion={cacheVersion} />
      </Row>
    </GridWrapper>
  )
}

export default PressreleasesListSection
