import { StoryblokComponent } from "@storyblok/react/rsc"

import { RegionsItem } from "@/types/NonSyncedTypes"
import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { PatientfeesPageStoryblok } from "@storyblok-types"
import PageContainer from "@ui-library/layout/PageContainer"
import { findColorName } from "@utils/utils"

interface PatientfeesProps {
  blok: BlokProps<PatientfeesPageStoryblok>["blok"]
  regions: RegionsItem[]
}

export const PatientfeesPage = ({ blok, regions }: PatientfeesProps) => {
  const backgroundColor = findColorName(blok.background)

  return (
    <>
      <PageContainer background={backgroundColor} {...withStoryblokEditable(blok)}>
        {blok.body?.map(nestedBlok => <StoryblokComponent blok={nestedBlok} regions={regions} key={nestedBlok._uid} />)}
      </PageContainer>
    </>
  )
}

export default PatientfeesPage
