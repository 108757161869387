import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicStaffGroupStoryblok } from "@storyblok-types"

import AccordionItem from "@ui-library/accordion/AccordionItem"
import { Paragraph } from "@ui-library/typography/Body"

export const ClinicStaffGroup = ({ blok }: BlokProps<ClinicStaffGroupStoryblok>) => {
  return (
    <AccordionItem value={blok._uid} title={blok.name} {...storyblokEditable(blok as SbBlokData)}>
      {blok.staff.map(s => (
        <div className="mb-4 last:mb-2" key={s._uid}>
          <Paragraph size="small" asChild className="!font-bold">
            <b>{s.name}</b>
          </Paragraph>
          <Paragraph size="small" className="!mb-0">
            {s.job_title}
          </Paragraph>
          <Paragraph size="small" className="!mb-0">
            {s.email}
          </Paragraph>
        </div>
      ))}
    </AccordionItem>
  )
}

export default ClinicStaffGroup
