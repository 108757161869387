import { ISbStories, ISbStoryData, SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import {
  ClinicItemType,
  DataSourceEntries,
  FactAdviceListItem,
  RegionsItem,
  VaccinationLocation
} from "@nonSyncedTypes"
import { ClinicConfigStoryblok, ConfigStoryblok, ListingPageStoryblok, PageStoryblok } from "@storyblok-types"

export interface BlokProps<T> {
  blok: T
}

export type ISbStoryblokType = ISbStoryData<
  BlokProps<string> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [index: string]: any
  }
>[]

export interface ClinicOverviewPageData {
  clinics?: ClinicItemType[]
  datasourceClinicTags?: DataSourceEntries[]
  datasourceRegionTags?: DataSourceEntries[]
}

export interface StoryblokData {
  key: number
  page: ISbStoryData<PageStoryblok | ListingPageStoryblok>
  config: ISbStoryData<ConfigStoryblok | ClinicConfigStoryblok>
  factAdvicePages?: FactAdviceListItem[]
  articles?: ISbStoryData[]
  perPage?: number
  currentPage?: number
  totalPages?: number
  vaccinationBusLocationList?: VaccinationLocation[]
  initialPressreleases?: ISbStories
  regions?: RegionsItem[]
  clinicOverviewPage?: ClinicOverviewPageData
}

const isSbBlokData = (blok: unknown): blok is SbBlokData => {
  return blok != null && typeof blok === "object" && "_uid" in blok && "component" in blok
}

export const withStoryblokComponent = (blok: SbBlokData | unknown) => {
  if (!isSbBlokData(blok)) {
    throw new Error("withStoryblokComponent must be used with a blok object")
  }
  return <StoryblokComponent key={blok._uid} blok={blok} />
}

export const withStoryblokEditable = (blok: { _uid: string; component: string }) => {
  return storyblokEditable(blok)
}
