"use client"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ChipStoryblok } from "@storyblok-types"

import { urlParser } from "@lib/urlParser"
import UIChipLink from "@ui-library/chip/ChipLink"
import UIChipTag from "@ui-library/chip/ChipTag"

export const Chip = ({ blok }: BlokProps<ChipStoryblok>) => {
  // const chipLink = `${blok.link?.cached_url}?section=${encodeURIComponent(blok.name)}`
  const chipLink = `${urlParser(blok.link?.cached_url)}`

  switch (blok.style) {
    case "primary":
      return <UIChipLink url={chipLink} text={blok.name} icon={blok.icon || ""} />
    case "secondary":
      return <UIChipTag text={blok.name} icon={blok.icon || ""} />
    default:
      throw new Error(`Unknown variant ${blok.variant}`)
  }
}

export default Chip
