const months = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December"
]

export const formatDate = (dateString: string, post = true) => {
  if (!dateString) return
  const date = new Date(dateString)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  let formattedDate = `${day} ${months[month]}`
  if (post) formattedDate = `${day} ${months[month]}, ${year}`

  return formattedDate
}
