"use client"

import { useEffect, useMemo, useState } from "react"

import Link from "next/link"

import ChevronRightIcon from "@layout/icons/ChevronRightIcon"

import { useConfigStore } from "@/store/config-store"
import HouseIcon from "@layout/icons/HouseIcon"
import { BreadcrumbItem } from "@ui-library/breadcrumbs/BreadcrumbItem"
import { Paragraph } from "@ui-library/typography/Body"

type Breadcrumb = {
  slug: string
  index: number
}

type LdBreadcrumb = {
  "@type": string
  position: number
  name: string
  item: string
}

const humanizeString = (str: string) => {
  return str.replace(/-/g, " ").replace(/^\w/, char => char.toUpperCase())
}

const Breadcrumbs = ({ slug }: { slug: string | undefined }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const [ldBreadcrumbs, setLdBreadcrumbs] = useState<LdBreadcrumb[]>([])
  const isEnglish = useConfigStore.getState().en

  useEffect(() => {
    if (!slug) return
    const newSlug = isEnglish ? slug.replace("en/", "") : slug
    const parts = newSlug.split("?")[0].split("/").filter(Boolean)
    const slugs: Breadcrumb[] = []
    const ldSlugs: LdBreadcrumb[] = []
    const slugBase = isEnglish ? "en/" : ""

    for (let i = 0; i < parts.length; i++) {
      const partialPath = parts.slice(0, i + 1).join("/")
      const item: Breadcrumb = {
        slug: `${slugBase}${partialPath.toLocaleLowerCase()}/`,
        index: i
      }
      slugs.push(item)
      const breadcrumbTitle = humanizeString(parts.slice(i, i + 1)[0])
      const breadcrumbFullLink = `${process.env.HOME_URL}/${item.slug}`
      const ldItem: LdBreadcrumb = {
        "@type": "ListItem",
        position: i + 1,
        name: breadcrumbTitle,
        item: breadcrumbFullLink
      }
      ldSlugs.push(ldItem)
    }
    setBreadcrumbs(slugs)
    setLdBreadcrumbs(ldSlugs)
  }, [slug, isEnglish])

  const homeSlug = isEnglish ? "/en" : "/"

  const ldBreadcrumbsScript = useMemo(
    () => ({
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [ldBreadcrumbs]
    }),
    [ldBreadcrumbs]
  )

  return (
    <>
      {ldBreadcrumbs.length > 0 && (
        <script
          id="breadcrumbsLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldBreadcrumbsScript)
          }}
        />
      )}
      <nav aria-label="breadcrumb" className="mb-6 hidden min-h-[24px] w-full lg:block">
        <ol className="m-0 flex list-none items-center justify-start p-0">
          <li className="inline-flex items-center text-neutral-50">
            <Paragraph size="small" asChild>
              <Link href={homeSlug} className="transition-colors hover:text-on-background">
                <HouseIcon />
              </Link>
            </Paragraph>
            <ChevronRightIcon className="mx-3" width={6} />
          </li>

          {breadcrumbs.map(breadcrumb => (
            <li className="inline-flex items-center text-neutral-50" key={breadcrumb.index}>
              <BreadcrumbItem
                item={breadcrumb.slug}
                breadcrumbCount={breadcrumbs.length}
                index={breadcrumb.index}
                current={breadcrumb.index === breadcrumbs.length - 1}
              />
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}

export default Breadcrumbs
