"use client"

import FilterMenu from "@/components/ui-library/menu/FilterMenu"
import { updateURLParameters } from "@/lib/updateURLParameters"
import { ArticleTagStory } from "@/types/NonSyncedTypes"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { useRouter } from "next/navigation"

interface ArticleFilteringProps {
  articleTags: ArticleTagStory[] | null
  currentTag: ArticleTagStory | undefined
}

const slug = "/artiklar"

export const ArticleFiltering = ({ articleTags, currentTag }: ArticleFilteringProps) => {
  const router = useRouter()

  const changeHandler = (articleTagId: string) => {
    const currentUrl = `${process.env.HOME_URL}${slug}`
    const tag = articleTags?.find(tag => tag.id === articleTagId)
    const updatedParams = { category: tag?.slug ?? "" }
    const updatedUrl = updateURLParameters(currentUrl, updatedParams)

    router.push(updatedUrl)
  }

  return (
    <GridWrapper className="mb-0 md:mb-0">
      <Row justifyContent="center">
        <Col grid={{ xs: 12 }} className="relative flex justify-center">
          <FilterMenu
            tags={articleTags}
            placeholder="Välj kategori"
            allLabel="Alla artiklar"
            param="category"
            className="sm:max-w-[20rem]"
            selectedValue={currentTag?.id}
            changeHandler={changeHandler}
          />
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default ArticleFiltering
