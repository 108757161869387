import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { AccordionSectionStoryblok } from "@storyblok-types"

import dynamic from "next/dynamic"

const UIAccordionSection = dynamic(() => import("@ui-library/accordion/AccordionSection"), {
  ssr: true
})

export const AccordionSection = ({ blok }: BlokProps<AccordionSectionStoryblok>) => {
  return (
    <UIAccordionSection
      heading={blok.heading}
      background={blok.background ?? ""}
      centered={blok.centered ?? false}
      sections={blok.sections?.map(section => ({
        title: section.title,
        content: section.content,
        uid: section._uid
      }))}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.button?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
    </UIAccordionSection>
  )
}

export default AccordionSection
