import * as Accordion from "@radix-ui/react-accordion"
import React, { ReactNode } from "react"

import ChevronDownIcon from "@layout/icons/ChevronDownIcon"
import { cn } from "@utils/utils"

export type AccordionItemProps = React.HTMLAttributes<HTMLDivElement> & {
  value: string
  title: string
  children: ReactNode
}

export const AccordionItem = ({ children, value, title, ...rest }: AccordionItemProps) => {
  return (
    <Accordion.Item className={cn("overflow-hidden border-b border-neutral-30 text-left")} value={value} {...rest}>
      <Accordion.Header className="m-0 flex">
        <AccordionTrigger>{title}</AccordionTrigger>
      </Accordion.Header>
      <AccordionContent>{children}</AccordionContent>
    </Accordion.Item>
  )
}

type AccordionTriggerProps = {
  children: ReactNode
  className?: string
}

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Trigger
      {...props}
      className={cn(
        "group/trigger flex w-full flex-row items-center justify-between border-0 bg-inherit py-5 pl-2 pr-0 text-left text-on-surface focus:outline-none focus-visible:outline-none",
        className
      )}
      ref={forwardedRef}
    >
      <span className="h4 mb-0 block w-full overflow-hidden">{children}</span>
      <ChevronDownIcon
        className="transition-transform duration-200 ease-rotate group-aria-expanded/trigger:rotate-180"
        aria-hidden
      />
    </Accordion.Trigger>
  )
)

AccordionTrigger.displayName = "AccordionTrigger"

type AccordionContentProps = {
  children: React.ReactNode
  className?: string
}

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      {...props}
      ref={forwardedRef}
      className={cn("overflow-hidden bg-inherit px-2 pb-4 text-on-surface", className)}
    >
      <div>{children}</div>
    </Accordion.Content>
  )
)

AccordionContent.displayName = "AccordionContent"

export default AccordionItem
