"use client"

import React from "react"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { SnackbarStoryblok } from "@storyblok-types"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Message } from "@ui-library/snackbar/message"

export const Snackbar = ({ blok }: BlokProps<SnackbarStoryblok>) => {
  const [showSnackbar, setShowSnackbar] = React.useState(true)

  const handleClose = () => {
    setShowSnackbar(false)
  }

  return (
    <>
      {blok.show_snackbar && showSnackbar && (
        <GridWrapper className="my-0 md:mb-8 md:mt-0">
          <Row>
            <Col>
              <Message
                message={blok.message}
                variant={blok.message_importance}
                buttonText={blok.cta_text}
                buttonUrl={blok.cta_link?.cached_url}
                onClose={handleClose}
              />
            </Col>
          </Row>
        </GridWrapper>
      )}
    </>
  )
}

export default Snackbar
