"use client"

import { useRef } from "react"
import { ListingStoreProps, useListingStore } from "./listing-store"

export const ListingStoreInitializer = ({ story }: ListingStoreProps) => {
  const initialized = useRef(false)

  if (!initialized.current) {
    useListingStore.setState({ story: story })
    initialized.current = true
  }

  return null
}
