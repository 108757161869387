import { FindHereData } from "@/components/storyblok-routes/clinics/MapsSection"

import Accordion from "@ui-library/accordion/Accordion"
import AccordionItem from "@ui-library/accordion/AccordionItem"
import { Paragraph } from "@ui-library/typography/Body"

export const FindHereMobile = ({
  public_transport_title,
  public_transport_content,
  car_title,
  car_content
}: FindHereData) => (
  <div className="lg:hidden">
    <Accordion type="single">
      <AccordionItem title={public_transport_title} value="public-transport">
        <Paragraph size="medium">{public_transport_content}</Paragraph>
      </AccordionItem>
      <AccordionItem title={car_title} value="car">
        <Paragraph size="medium">{car_content}</Paragraph>
      </AccordionItem>
    </Accordion>
  </div>
)
