import { BlokProps } from "@helpers/StoryblokHelpers"
import { ManagementMemberStoryblok } from "@storyblok-types"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import { Paragraph } from "@ui-library/typography/Body"
import { Label } from "@ui-library/typography/Labels"

import Image from "next/image"

export const ManagementMember = ({ blok }: BlokProps<ManagementMemberStoryblok>) => {
  return (
    <article
      className="h-full overflow-hidden rounded-2xl bg-surface shadow-elevation-1"
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.photo && (
        <figure className="relative min-h-[260px] w-full">
          <Image src={blok.photo?.filename || ""} alt={blok.name || ""} fill className="object-cover" />
        </figure>
      )}
      <div className="p-6">
        <Label size="xsmall">{blok.title}</Label>
        <h3>{blok.name}</h3>
        <Paragraph size="small">
          {blok.born && <span className="block">{`Född: ${blok.born}`}</span>}
          {blok.started_at_doktor && <span>{`Anställd sedan ${blok.started_at_doktor}.`}</span>}
        </Paragraph>
      </div>
    </article>
  )
}

export default ManagementMember
