"use client"

import DefaultArticlesButtonLink from "@/components/storyblok-routes/articles/DefaultArticlesButtonLink"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { defaultSbParams } from "@/lib/defaultSbParams"
import { FeaturedArticlesStoryblok } from "@/types/component-types"

import {
  ISbStories,
  ISbStoriesParams,
  ISbStoryData,
  SbBlokData,
  StoryblokComponent,
  getStoryblokApi,
  storyblokEditable
} from "@storyblok/react/rsc"

import ArticleCard from "@ui-library/card/ArticleCard"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"
import { addDays, format } from "date-fns"
import { useEffect, useState } from "react"

export const FeaturedArticles = ({ blok }: BlokProps<FeaturedArticlesStoryblok>) => {
  const [stories, setStories] = useState<ISbStoryData[]>([])

  const getArticles = async (
    perPage: number,
    storyIds: string[] = [],
    filter = "",
    authorRef = "",
    contentInspectorRef = ""
  ) => {
    const articlesPerPage = perPage ? perPage : 6
    const tomorrowDate = addDays(new Date(), 1)
    const tomorrow = format(tomorrowDate, "yyyy-MM-dd")

    const customParams: ISbStoriesParams = {
      ...defaultSbParams,
      starts_with: "artiklar/",
      excluding_slugs: "artiklar/",
      sort_by: "content.published_date:desc",
      is_startpage: false,
      per_page: articlesPerPage,
      by_uuids: storyIds ? storyIds.join(",") : undefined,
      filter_query: {
        article_tags: {
          any_in_array: filter ? filter : null
        },
        author_ref: {
          any_in_array: authorRef ? authorRef : null
        },
        content_inspectors_ref: {
          any_in_array: contentInspectorRef ? contentInspectorRef : null
        },
        published_date: {
          lt_date: tomorrow
        }
      }
    }

    const storyblokApi = getStoryblokApi()
    const { data: articles } = (await storyblokApi.get(`cdn/stories/`, customParams)) as ISbStories

    setStories(articles?.stories as ISbStoryData[])
  }

  useEffect(() => {
    getArticles(
      2,
      blok.selected_articles,
      blok.selected_category as string,
      blok.selected_author,
      blok.selected_content_inspector
    )
  }, [blok.selected_articles, blok.selected_category, blok.selected_author, blok.selected_content_inspector])

  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-8 lg:text-center">
          <h2>{blok.title}</h2>
          <Paragraph size="medium">{blok.content}</Paragraph>
        </Col>

        {stories.map(article => (
          <Col key={article.id} grid={{ xs: 12, lg: 6 }}>
            <ArticleCard article={article} />
          </Col>
        ))}

        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mt-12 flex justify-center">
          {(blok.buttons ?? []).length > 0 &&
            blok.buttons?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}

          <DefaultArticlesButtonLink blok={blok} />
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default FeaturedArticles
