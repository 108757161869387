const isEmailValid = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return emailPattern.test(email)
}

export const sendBrazeRequest = async (email: string) => {
  const token = process.env.BRAZE_ACCESS_TOKEN

  if (!email) {
    return {
      status: false,
      message: "Vänligen fyll i din mailadress."
    }
  } else {
    const emailStatus = isEmailValid(email)
    if (!emailStatus) {
      return {
        status: false,
        message: "Din mailadress verkar vara ogiltig."
      }
    }
  }

  const checkIfUserExists = await fetch("https://rest.fra-01.braze.eu/users/export/ids", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email_address: email
    })
  })

  const response = await checkIfUserExists.json()

  if (response.users && response.users.length > 0) {
    const user = response.users[0]
    if (user) {
      if (user.email_subscribe === "subscribed") {
        return {
          status: true,
          message: "Du kommer nu att få nyhetsbrev från Doktor.se"
        }
      } else {
        const changeUserToSubscribed = await fetch("https://rest.fra-01.braze.eu/email/status", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            subscription_state: "subscribed"
          })
        })
        const subResponse = await changeUserToSubscribed.json()
        if (subResponse.message) {
          return {
            status: true,
            message: "Du kommer nu att få nyhetsbrev från Doktor.se"
          }
        } else {
          return {
            status: false,
            message: "Ajdå, nu blev det fel någonstans. Testa att ladda om sidan och pröva igen."
          }
        }
      }
    }
  } else {
    const createUser = await fetch("https://rest.fra-01.braze.eu/users/track", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        attributes: [
          {
            _update_existing_only: false,
            user_alias: {
              alias_name: email,
              alias_label: "Signup Newsletter"
            },
            email: email
          }
        ],
        events: [
          {
            _update_existing_only: false,
            user_alias: {
              alias_name: email,
              alias_label: "Signup Newsletter"
            },
            name: "Signup Newsletter",
            time: new Date().toISOString()
          }
        ]
      })
    })

    const response = await createUser.json()

    if (response.message) {
      return {
        status: true,
        message: "Grattis! Du kommer nu att få nyhetsbrev från Doktor.se"
      }
    } else {
      return {
        status: false,
        message: "Ajdå, nu blev det fel någonstans. Testa att ladda om sidan och pröva igen."
      }
    }
  }

  return {
    status: false,
    message: "Ajdå, nu blev det fel någonstans. Testa att ladda om sidan och pröva igen."
  }
}
