import { ISbStoryData } from "@storyblok/react/rsc"

import Image from "next/image"
import Link from "next/link"

import { calculateReadingTimeFromMultidimensionalArray } from "@lib/calculateReadingTimeFromRichText"
import { formatDate } from "@lib/formatDate"
import { truncateStringByWords } from "@lib/truncateString"

import { urlParser } from "@lib/urlParser"
import { Paragraph } from "@ui-library/typography/Body"
import { H3 } from "@ui-library/typography/Headings"
import { cn } from "@utils/utils"

export const FeaturedArticleCard = ({ article, inverted }: { article: ISbStoryData; inverted: boolean }) => {
  const formattedDate = formatDate(article.content.published_date)
  const readingTime = calculateReadingTimeFromMultidimensionalArray(article.content.body, article.content.preamble)
  const preamble = truncateStringByWords(article.content.preamble, 28)
  const excerpt = article.content.excerpt

  return (
    <Link
      href={urlParser(article.full_slug)}
      className="block h-full overflow-hidden rounded-2xl bg-surface shadow-elevation-1 lg:flex lg:justify-start"
    >
      <figure
        className={cn("relative block h-64 w-full lg:h-96 lg:w-[60%]", { "order-2": !inverted, "order-1": inverted })}
      >
        {article.content.featured_image && (
          <Image
            src={article.content.featured_image.filename || ""}
            alt={article.content.featured_image.alt}
            quality={20}
            width={760}
            height={0}
            sizes="(max-width: 768px) 100vw, 50vw"
            className="absolute inset-0 h-full w-full object-cover object-top"
          />
        )}
      </figure>
      <div
        className={cn("flex min-h-min w-full flex-col px-8 py-6 lg:block lg:h-auto lg:w-[40%]", {
          "order-2": inverted,
          "order-1": !inverted
        })}
      >
        <H3>{article.name}</H3>
        <Paragraph size="medium">{excerpt || preamble}</Paragraph>

        <Paragraph size="small" asChild className="mb-0 text-neutral-50">
          <span>
            {formattedDate} ・ {readingTime} min
          </span>
        </Paragraph>
      </div>
    </Link>
  )
}

export default FeaturedArticleCard
