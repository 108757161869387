export const formatAsTelephoneLink = (inputString: string | undefined) => {
  if (!inputString) return
  const regex = /^[0-9\s-]+$/

  if (regex.test(inputString)) {
    const cleanedString = inputString.replace(/[-\s]/g, "")

    return `tel:${cleanedString}`
  } else {
    return undefined
  }
}
