import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicStaffSectionStoryblok } from "@storyblok-types"

import UIAccordion from "@ui-library/accordion/Accordion"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"

export const ClinicStaffSection = ({ blok }: BlokProps<ClinicStaffSectionStoryblok>) => (
  <>
    {blok.intro?.map(item => <StoryblokComponent key={item._uid} blok={item} />)}
    <GridWrapper background="surface">
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
          <UIAccordion type="single" {...storyblokEditable(blok as SbBlokData)}>
            {blok.groups?.map(item => <StoryblokComponent key={item._uid} blok={item} />)}
          </UIAccordion>
        </Col>
      </Row>
    </GridWrapper>
  </>
)

export default ClinicStaffSection
