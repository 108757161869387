import Image from "next/image"

import { Paragraph } from "@/components/ui-library/typography/Body"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { ImageSectionStoryblok } from "@storyblok-types"

export const ImageSection = ({ blok }: BlokProps<ImageSectionStoryblok>) => {
  return (
    <figure className="w-full">
      <Image
        src={blok.image.filename || ""}
        alt={blok.image.alt || ""}
        width={610}
        height={0}
        className="rounded-2xl"
      />
      {blok.image_text && <Paragraph className="pt-1 text-neutral-50">{blok.image_text}</Paragraph>}
    </figure>
  )
}

export default ImageSection
