import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { FactSectionStoryblok } from "@storyblok-types"

import { sanitizeString } from "@/lib/sanitizeString"
import Richtext from "@ui-library/richtext/Richtext"
import { H3 } from "@ui-library/typography/Headings"

export const FactSection = ({ blok }: BlokProps<FactSectionStoryblok>) => {
  const blokId = `section-${sanitizeString(blok._uid)}`
  return (
    <article className="my-8 first:mb-8 first:mt-0" id={blokId} {...storyblokEditable(blok as SbBlokData)}>
      <H3>{blok.title}</H3>
      <Richtext content={blok.content} />
    </article>
  )
}

export default FactSection
