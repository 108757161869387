import { Paragraph } from "@/components/ui-library/typography/Body"
import { H2 } from "@/components/ui-library/typography/Headings"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { TestimonialStoryblok } from "@/types/component-types"
import dynamic from "next/dynamic"

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

export const Testimonial = ({ blok }: BlokProps<TestimonialStoryblok>) => {
  return (
    <div className="flex shrink items-center text-on-secondary-10 max-lg:my-2 max-lg:w-full">
      <DoktorIcon iconName={blok.icon || ""} width={64} height={64} className="max-lg:h-6 max-lg:w-6" />
      <article className="ml-4">
        <H2 className="m-0 max-lg:mr-2 max-lg:inline-block max-lg:!text-[18px]">{blok.rating_or_number}</H2>
        <Paragraph size="large" className="max-lg:inline-block max-lg:!text-[18px]">
          {blok.description}
        </Paragraph>
      </article>
    </div>
  )
}

export default Testimonial
