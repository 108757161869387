import Image from "next/image"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { AuthorPageStoryblok } from "@storyblok-types"

import Breadcrumbs from "@/components/blocks/sections/Breadcrumbs"
import Richtext from "@/components/ui-library/richtext/Richtext"
import { useStoryStore } from "@/store/story-store"
import { StoryblokComponent } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"

interface AuthorPageProps {
  blok: BlokProps<AuthorPageStoryblok>["blok"]
}

export const AuthorPage = ({ blok }: AuthorPageProps) => {
  const story = useStoryStore.getState().story

  const grid = {
    xs: 12,
    lg: 8,
    xl: 6
  }

  return (
    <>
      <PageContainer>
        <GridWrapper
          className="mb-0 mt-8 rounded-b-none rounded-t-3xl bg-surface pt-12 md:my-16 md:rounded-b-none md:rounded-t-none md:bg-background md:pt-0"
          {...withStoryblokEditable(blok)}
        >
          <Row justifyContent="center" justifyContentMobile="center" className="pb-12">
            <Col grid={grid}>
              <Breadcrumbs slug={story?.full_slug} />
              <h1>{blok.full_name_text}</h1>
              <span className="text-neutral-50">{blok.subheader_text}</span>
            </Col>

            <Col grid={{ xs: 12, lg: 10, xl: 8 }}>
              {blok.featured_image && (
                <figure className="relative mb-6 aspect-[1.85] w-full overflow-hidden rounded-2xl lg:mb-10 lg:aspect-[2.5] lg:rounded-3xl">
                  <Image
                    src={`${blok.featured_image.filename}`}
                    priority
                    fill
                    alt={blok.featured_image.alt || ""}
                    className="absolute inset-0 h-full w-full overflow-hidden object-cover"
                  />
                </figure>
              )}
            </Col>

            <Col grid={grid}>
              <Richtext content={blok.about_text} />
            </Col>
          </Row>
        </GridWrapper>

        {blok.latest_articles?.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
      </PageContainer>
    </>
  )
}

export default AuthorPage
