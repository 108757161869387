import { Button } from "@/components/ui-library/button/Button"
import OpenNewTabIcon from "@/layout/icons/OpenNewTabIcon"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { trackEvent } from "@lib/amplitude/amplitude"
import { urlParser } from "@lib/urlParser"
import { ButtonLinkStoryblok } from "@storyblok-types"
import Link from "next/link"
import { usePathname } from "next/navigation"

interface ButtonLinkBlokProps<T> extends BlokProps<T> {
  onClick?: () => void
  clinicId?: string
  variant?: "primary" | "secondary" | "accent"
  className?: string
}

export const ButtonLink = ({ blok, variant, clinicId, className }: ButtonLinkBlokProps<ButtonLinkStoryblok>) => {
  const pathname = usePathname()
  const linkType = blok.link.linktype
  const linkUrl = linkType == "email" ? `mailto:${blok.link.cached_url}` : blok.link.cached_url
  const finalLinkUrl = linkType == "email" ? linkUrl : urlParser(linkUrl)
  const showIcon = blok.link?.linktype === "url" && blok.link?.target === "_blank"
  const eventName = blok.custom_event ? blok.custom_event : blok.event
  const isListMeLink = !!clinicId && finalLinkUrl?.includes("/lista-dig")
  const buttonUrl = isListMeLink ? `${finalLinkUrl}?clinicId=${clinicId}` : finalLinkUrl

  const buttonVariant = variant ? variant : blok.variant

  let linkTarget = "_self"
  const isOnelink = blok.link.cached_url?.includes("onelink")
  if (isOnelink) linkTarget = "_top"

  return (
    <Button variant={buttonVariant} asChild className={className}>
      <Link
        href={buttonUrl || "#"}
        target={blok.link.target ? String(blok.link.target) : linkTarget}
        onClick={eventName ? () => trackEvent(eventName, { page_path: pathname }) : undefined}
      >
        {blok.name}
        {showIcon && <OpenNewTabIcon className="ml-2" width={16} height={16} />}
      </Link>
    </Button>
  )
}

export default ButtonLink
