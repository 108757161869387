import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import React from "react"

import { cn } from "@/utils/utils"

const CTAVariants = cva("mb-4 w-full rounded-2xl p-6", {
  variants: {
    variant: {
      primary: "bg-primary-10 text-on-primary-10",
      secondary: "bg-secondary-10 text-on-secondary-10",
      accent: "bg-background text-on-accent"
    },
    alignment: {
      default: "",
      center: "text-center"
    },
    elevated: {
      default: "",
      elevated: "shadow-elevation-1"
    }
  },
  defaultVariants: {
    variant: "secondary",
    alignment: "default",
    elevated: "default"
  }
})

export interface CTAProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof CTAVariants> {
  asChild?: boolean
}

const CTABlock = React.forwardRef<HTMLButtonElement, CTAProps>(
  ({ className, variant, alignment, elevated, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "article"

    return <Comp className={cn(CTAVariants({ variant, alignment, elevated, className }))} ref={ref} {...props} />
  }
)

CTABlock.displayName = "Button"

export { CTABlock, CTAVariants }
