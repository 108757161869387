"use client"

import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ClinicConfigStoryblok, ContactClinicMapStoryblok, TextStoryblok } from "@storyblok-types"

import { useConfigStore } from "@/store/config-store"
import MapIcon from "@layout/icons/MapIcon"
import OldPhoneIcon from "@layout/icons/OldPhoneIcon"
import TimeIcon from "@layout/icons/TimeIcon"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Skeleton from "@ui-library/skeleton/Skeleton"
import { Paragraph } from "@ui-library/typography/Body"
import { DisplayHeader } from "@ui-library/typography/Headings"
import { Label } from "@ui-library/typography/Labels"
import dynamic from "next/dynamic"

const Map = dynamic(() => import("@ui-library/google-map/Map"), {
  ssr: false,
  loading: () => <Skeleton className="absolute h-full w-full rounded-none" />
})

interface ContactClinicMapProps {
  blok: BlokProps<ContactClinicMapStoryblok>["blok"]
}

export const ContactClinicMap = ({ blok }: ContactClinicMapProps) => {
  const IconSize = 20
  const config = useConfigStore.getState().config as ClinicConfigStoryblok

  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="lg:text-center">
          <DisplayHeader>{blok.title}</DisplayHeader>
          <Paragraph size="medium">{blok.preamble}</Paragraph>
        </Col>

        <Col grid={{ xs: 12 }} className="mt-12 flex flex-wrap items-stretch justify-start">
          <div className="relative min-h-[242px] w-full overflow-hidden rounded-t-2xl lg:min-h-[444px] lg:w-2/4 lg:rounded-l-3xl lg:rounded-tr-none">
            <Map lat={config.latitude || ""} lng={config.longitude || ""} />
          </div>

          <div className="w-full rounded-b-2xl bg-primary-50 p-4 lg:flex lg:w-2/4 lg:flex-col lg:justify-center lg:rounded-r-3xl lg:rounded-bl-none lg:p-12">
            {config.opening_hours && (
              <div className="my-4 w-full text-surface">
                <em className="mb-2 flex items-center justify-start not-italic">
                  <TimeIcon width={IconSize} className="mr-4" />
                  <Label size="small">Öppettider</Label>
                </em>
                {config.opening_hours?.map((time: TextStoryblok) => (
                  <Paragraph key={time._uid} size="small" className="pl-[2.25rem]">
                    {time.text}
                  </Paragraph>
                ))}
              </div>
            )}

            <div className="my-4 w-full text-surface">
              <em className="mb-2 flex items-center justify-start not-italic">
                <MapIcon width={IconSize} className="mr-4" />
                <Label size="small">Adress</Label>
              </em>
              <Paragraph size="small" className="pl-[2.25rem]">
                {config.address}
              </Paragraph>
            </div>

            <div className="my-4 w-full text-surface">
              <em className="mb-2 flex items-center justify-start not-italic">
                <OldPhoneIcon width={IconSize} className="mr-4" />
                <Label size="small">Telefon</Label>
              </em>
              <Paragraph size="small" className="pl-9">
                <a href={`tel:${config.phone_number}`}>{config.phone_number}</a>
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default ContactClinicMap
