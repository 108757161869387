"use client"

import { urlParser } from "@/lib/urlParser"
import { BlokProps } from "@helpers/StoryblokHelpers"
import { trackEvent } from "@lib/amplitude/amplitude"
import { ArrowLinkStoryblok } from "@storyblok-types"

import UIArrowLink from "@ui-library/link/ArrowLink"
import { usePathname } from "next/navigation"

type ArrowLinkProps = {
  blok: BlokProps<ArrowLinkStoryblok>["blok"]
  color?: string
  variant?: string
}

export const ArrowLink = ({ blok, color, variant }: ArrowLinkProps) => {
  const pathname = usePathname()
  const linkColor = color ? color : blok.color
  const colorVariant = variant ? variant : linkColor
  const eventName = blok.custom_event ? blok.custom_event : blok.event

  return (
    <UIArrowLink
      url={urlParser(blok.link.cached_url)}
      target={blok.link.target ? String(blok.link.target) : "_self"}
      text={blok.name}
      color={colorVariant}
      onClick={eventName ? () => trackEvent(eventName, { page_path: pathname }) : undefined}
    />
  )
}

export default ArrowLink
