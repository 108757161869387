import { BlokProps } from "@helpers/StoryblokHelpers"
import { formatAsTelephoneLink } from "@lib/formatAsPhonenumber"
import { ContactCardItemStoryblok } from "@storyblok-types"
import { Label } from "@ui-library/typography/Labels"

export const ContactCardItem = ({ blok }: BlokProps<ContactCardItemStoryblok>) => {
  const isPhonenumber = formatAsTelephoneLink(blok.value)

  return (
    <>
      <Label size="small" className="w-2/4">
        {blok.label}:
      </Label>
      <Label size="small" className="w-2/4 hyphens-auto">
        {isPhonenumber ? <a href={isPhonenumber}>{blok.value}</a> : <>{blok.value}</>}
      </Label>
    </>
  )
}

export default ContactCardItem
