import { VaccinationLocationDateStoryblok } from "@storyblok-types"

interface GroupedDates {
  title: string
  dates: VaccinationLocationDateStoryblok[]
}

export const groupDatesByMonth = (dateArray: VaccinationLocationDateStoryblok[]): GroupedDates[] => {
  const groupedDates: Record<string, VaccinationLocationDateStoryblok[]> = {}

  for (const dateObj of dateArray) {
    if (!dateObj.date) {
      continue // Skip entries with no date
    }

    const date = new Date(dateObj.date)
    const monthName = date.toLocaleString("default", { month: "long" })

    if (!groupedDates[monthName]) {
      groupedDates[monthName] = []
    }

    groupedDates[monthName].push(dateObj)
  }

  const sortedDates: GroupedDates[] = Object.entries(groupedDates).map(([month, dates]) => ({
    title: month,
    dates: dates
  }))

  return sortedDates
}
