const RightArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.3 15.2998C7.11667 15.1165 7.02067 14.8831 7.012 14.5998C7.004 14.3165 7.09167 14.0831 7.275 13.8998L12.175 8.9998H1C0.716667 8.9998 0.479 8.9038 0.287 8.7118C0.0956668 8.52047 0 8.28314 0 7.9998C0 7.71647 0.0956668 7.4788 0.287 7.2868C0.479 7.09547 0.716667 6.9998 1 6.9998H12.175L7.275 2.0998C7.09167 1.91647 7.004 1.68314 7.012 1.3998C7.02067 1.11647 7.11667 0.883138 7.3 0.699804C7.48333 0.516471 7.71667 0.424805 8 0.424805C8.28333 0.424805 8.51667 0.516471 8.7 0.699804L15.3 7.2998C15.4 7.38314 15.471 7.48714 15.513 7.6118C15.5543 7.73714 15.575 7.86647 15.575 7.9998C15.575 8.13314 15.5543 8.25814 15.513 8.3748C15.471 8.49147 15.4 8.5998 15.3 8.6998L8.7 15.2998C8.51667 15.4831 8.28333 15.5748 8 15.5748C7.71667 15.5748 7.48333 15.4831 7.3 15.2998Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default RightArrow
