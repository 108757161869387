import { BlokProps } from "@helpers/StoryblokHelpers"
import { ArticlePageStoryblok, FactAdvicePageStoryblok, FeaturedArticlesStoryblok } from "@storyblok-types"

import FeaturedArticles from "@/components/blocks/sections/FeaturedArticles"
import { useConfigStore } from "@/store/config-store"
import { useMemo } from "react"

export const DefaultLatestArticle = ({ blok }: BlokProps<ArticlePageStoryblok | FactAdvicePageStoryblok>) => {
  const { strings } = useConfigStore.getState()

  const hasFeaturedArticlesBlock = useMemo(
    () => !!blok.blocks?.find(block => block.component === "featured_articles"),
    [blok.blocks]
  )

  const defaultFeaturedArticlesProps: FeaturedArticlesStoryblok = {
    component: "featured_articles",
    title: strings?.articles.latestArticlesTitle,
    content: strings?.articles.latestArticlesContent
  }

  if (hasFeaturedArticlesBlock) {
    return undefined
  }

  return <FeaturedArticles blok={defaultFeaturedArticlesProps}></FeaturedArticles>
}

export default DefaultLatestArticle
