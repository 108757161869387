"use client"

import ArrowForwardIcon from "@layout/icons/ArrowForwardIcon"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import Link from "next/link"
import { useSearchParams } from "next/navigation"
import { forwardRef, HTMLAttributes } from "react"

type PaginationItem = {
  pageLink: boolean
  url?: string
  pageNumber?: number
  label?: number | JSX.Element
  visible: boolean
}

const DEFAULT_PAGINATION_ITEM: PaginationItem = {
  pageLink: true,
  url: "",
  pageNumber: undefined,
  label: undefined,
  visible: false
}

const generatePaginationItem = (options: Partial<PaginationItem>): PaginationItem => {
  return { ...DEFAULT_PAGINATION_ITEM, ...options }
}

type PaginationProps = {
  slug: string | undefined
  currentPage: number
  totalPages: number
}

const Pagination = ({ slug, currentPage, totalPages }: PaginationProps) => {
  const startPage = Math.max(2, currentPage - 2)
  const endPage = Math.min(totalPages - 1, currentPage + 2)
  const url = `/${slug}`

  const pageLinks: PaginationItem[] = Array.from({ length: totalPages - 2 }, (_, index) => {
    const page = index + 2
    const pageLink = generatePaginationItem({
      pageLink: true,
      url,
      pageNumber: page,
      label: page,
      visible: page <= endPage && page >= startPage
    })
    return pageLink
  })

  const previousLink = generatePaginationItem({
    pageLink: true,
    url,
    pageNumber: currentPage - 1,
    label: <ArrowForwardIcon className="rotate-180" />,
    visible: currentPage !== 1
  })

  const nextLink = generatePaginationItem({
    pageLink: true,
    url,
    pageNumber: currentPage + 1,
    label: <ArrowForwardIcon />,
    visible: currentPage !== totalPages
  })

  const firstLink = generatePaginationItem({
    pageLink: true,
    url,
    pageNumber: 1,
    label: 1,
    visible: true
  })

  const lastLink = generatePaginationItem({
    pageLink: true,
    url,
    pageNumber: totalPages,
    label: totalPages,
    visible: true
  })

  const firstEllipsis = generatePaginationItem({
    pageLink: false,
    visible: currentPage >= 5
  })

  const lastEllipsis = generatePaginationItem({
    pageLink: false,
    visible: currentPage <= totalPages - 4
  })

  const paginationItems: PaginationItem[] = [
    previousLink,
    firstLink,
    firstEllipsis,
    ...pageLinks,
    lastEllipsis,
    lastLink,
    nextLink
  ]

  return (
    <nav className="flex flex-wrap items-center justify-center">
      {paginationItems.map((item, index) => {
        if (item.pageLink) {
          return (
            <PaginationLink
              key={`pagination-link-${index}`}
              link={item}
              currentPage={currentPage}
              totalPages={totalPages}
              className={cn(item.visible && "inline-flex")}
            >
              <Label size="small">{item.label}</Label>
            </PaginationLink>
          )
        } else {
          return (
            <span
              key={`pagination-ellipsis-${index}`}
              className={cn(
                "m-1 hidden h-10 w-10 items-center justify-center rounded-lg bg-secondary-10 text-on-secondary-10",
                item.visible && "inline-flex"
              )}
            >
              ...
            </span>
          )
        }
      })}
    </nav>
  )
}

export default Pagination

interface PaginationLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  link: PaginationItem
  currentPage: number
  totalPages: number
}

const PaginationLink = forwardRef<HTMLAnchorElement, PaginationLinkProps>(
  ({ link, children, className, currentPage, totalPages, ...props }, ref) => {
    const searchParams = useSearchParams()
    if (link.pageNumber === undefined || link.pageNumber === 0 || link.pageNumber > totalPages) return null

    return (
      <Link
        ref={ref}
        href={{ pathname: link.url, query: { ...Object.fromEntries(searchParams), page: link.pageNumber } }}
        prefetch={false}
        className={cn(
          "m-1 hidden h-10 w-10 items-center justify-center rounded-lg bg-secondary-10 text-on-secondary-10 transition-colors hover:bg-on-secondary-10 hover:text-secondary-10",
          link.label === currentPage && "bg-on-secondary-10 text-secondary-10",
          className
        )}
        {...props}
      >
        {children}
      </Link>
    )
  }
)

PaginationLink.displayName = "PaginationLink"
