import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ContentPageStoryblok } from "@storyblok-types"

import { useStoryStore } from "@/store/story-store"
import Breadcrumbs from "@blocks/sections/Breadcrumbs"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import PageContainer from "@ui-library/layout/PageContainer"
import Richtext from "@ui-library/richtext/Richtext"

interface FactAdvicePageProps {
  blok: BlokProps<ContentPageStoryblok>["blok"]
}

export const ContentPage = ({ blok }: FactAdvicePageProps) => {
  const story = useStoryStore.getState().story

  const grid = {
    xs: 12,
    lg: 8,
    xl: 6
  }

  return (
    <>
      <PageContainer>
        <GridWrapper
          className="mb-0 mt-8 rounded-b-none rounded-t-3xl bg-surface pt-12 md:my-16 md:rounded-b-none md:rounded-t-none md:bg-background md:pt-0"
          {...withStoryblokEditable(blok)}
        >
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <Breadcrumbs slug={story?.full_slug} />
              {blok.page_heading && <h1>{blok.page_heading}</h1>}
              {blok.page_intro && <Richtext preamble content={blok.page_intro} />}
            </Col>
          </Row>
        </GridWrapper>

        <GridWrapper background="surface" className="my-0 rounded-none md:rounded-3xl">
          <Row justifyContent="center" justifyContentMobile="center">
            <Col grid={grid}>
              <Richtext content={blok.page_content} />
            </Col>
          </Row>
        </GridWrapper>
      </PageContainer>
    </>
  )
}

export default ContentPage
