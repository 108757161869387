import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ContentSectionStoryblok } from "@storyblok-types"

import { cn } from "@/utils/utils"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Richtext from "@ui-library/richtext/Richtext"
import HeadingComponent from "@ui-library/typography/HeadingComponent"

export const ContentSection = ({ blok }: BlokProps<ContentSectionStoryblok>) => {
  const gridSize = {
    xs: 12,
    md: 10,
    lg: 8,
    xl: blok.narrow_layout ? 6 : 8
  }

  return (
    <GridWrapper
      className={cn(blok.remove_margin && "my-0 lg:my-0")}
      background={blok.background}
      {...storyblokEditable(blok as SbBlokData)}
    >
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={gridSize}>
          <div className={cn("text-left lg:text-center", blok.left_align_content && "lg:text-left")}>
            {blok.title && (
              <HeadingComponent title={blok.title} tag={blok.heading_size || "h3"} style={blok.heading_style || "h3"} />
            )}
            {blok.content && <Richtext content={blok.content} />}
          </div>

          {blok.button_link && blok.button_link.length > 0 && (
            <div className="mt-6 flex justify-start lg:mt-10 lg:justify-center">
              {blok.button_link?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
            </div>
          )}
        </Col>
      </Row>
    </GridWrapper>
  )
}

export default ContentSection
