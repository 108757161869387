const TimeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M64.8594 0.589691C82.7683 0.589691 98.9766 7.85007 110.724 19.5844C122.458 31.3187 129.719 47.5401 129.719 65.4491C129.719 83.358 122.458 99.5663 110.724 111.314C98.9897 123.048 82.7683 130.308 64.8594 130.308C46.9504 130.308 30.7421 123.048 18.9947 111.314C7.26038 99.5794 0 83.358 0 65.4491C0 47.5401 7.26038 31.3318 18.9947 19.5844C30.7291 7.85007 46.9504 0.589691 64.8594 0.589691ZM61.9814 24.1892C61.9814 22.5932 63.2765 21.3112 64.8594 21.3112C66.4423 21.3112 67.7374 22.6063 67.7374 24.1892V64.3633L81.1069 79.3026C82.1665 80.48 82.0619 82.2984 80.8845 83.358C79.7072 84.4176 77.8888 84.313 76.8292 83.1356L62.884 67.5683C62.3215 67.045 61.9683 66.2994 61.9683 65.4621V24.2023L61.9814 24.1892ZM5.82138 63.1598H9.4712C11.0672 63.1598 12.3492 64.4548 12.3492 66.0377C12.3492 67.6206 11.0541 68.9157 9.4712 68.9157H5.88679C6.75019 83.842 13.1603 97.2901 23.0893 107.219C33.1492 117.279 46.8196 123.728 61.9945 124.448V120.981C61.9945 119.385 63.2896 118.103 64.8725 118.103C66.4554 118.103 67.7504 119.398 67.7504 120.981V124.448C82.9253 123.715 96.5957 117.266 106.656 107.219C116.676 97.1985 123.112 83.5804 123.884 68.4709H119.829C118.233 68.4709 116.951 67.1759 116.951 65.593C116.951 64.0101 118.246 62.715 119.829 62.715H123.897C123.204 47.4878 116.742 33.752 106.656 23.6528C96.5042 13.5014 82.6637 7.02592 67.3057 6.39799V10.6365C67.3057 12.2325 66.0106 13.5145 64.4277 13.5145C62.8448 13.5145 61.5497 12.2194 61.5497 10.6365V6.43724C46.558 7.27447 33.0576 13.6976 23.0893 23.6528C12.8986 33.8435 6.41006 47.7364 5.82138 63.1467V63.1598Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TimeIcon
