import { ISbStories, ISbStoriesParams } from "@storyblok/react/rsc"
import StoryblokClient from "storyblok-js-client"
import { defaultSbParams } from "./defaultSbParams"

const PER_PAGE = 50

type StoryblokEndpoint = "stories" | "datasource_entries"

export interface FetchDataOptions {
  parentSlug?: string
  customParams?: ISbStoriesParams
  endpoint?: StoryblokEndpoint
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: "auto",
    type: "memory"
  }
})

const createParams = (options: FetchDataOptions): ISbStoriesParams => {
  if (options.customParams) {
    return { ...options.customParams }
  } else if (options.parentSlug) {
    return {
      ...defaultSbParams,
      starts_with: options.parentSlug,
      is_startpage: false,
      per_page: PER_PAGE,
      excluding_fields: "content"
    }
  } else {
    throw new Error("Either parentSlug or customParams must be provided.")
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchDataWithPagination = async <T extends any[]>(options: FetchDataOptions): Promise<T> => {
  const params = createParams(options)
  const endpoint: StoryblokEndpoint = options.endpoint || "stories"

  const { total } = await Storyblok.get(`cdn/${endpoint}`, {
    ...params,
    page: 1,
    per_page: 1
  })

  const results = await Promise.all(
    Array(Math.ceil(total / PER_PAGE))
      .fill(0)
      .map(async (_, index) => {
        const { data } = await Storyblok.get(`cdn/${endpoint}`, {
          ...params,
          page: index + 1
        })
        if (endpoint === "datasource_entries") {
          return data[endpoint] as T
        } else {
          return data[endpoint] as ISbStories
        }
      })
  )

  return results.flat() as T
}
