import {
  useSeoArticleScript,
  useSeoFaqScript,
  useSeoHowToScript,
  useSeoMedicalConditionScript,
  useSeoMedicalWebPageScript
} from "@/hooks/seo-schema/useSeoHooks"
import { StoryblokPageTemplates } from "@/store/story-store"
import { ArticlePageStoryblok, FactAdvicePageStoryblok } from "@/types/component-types"
import { BlokProps } from "../StoryblokHelpers"

interface ArticleFaktaRadPageScriptsProps {
  story: StoryblokPageTemplates
  blok: BlokProps<ArticlePageStoryblok>["blok"] | BlokProps<FactAdvicePageStoryblok>["blok"]
  featuredImage?: string
}

export const ArticleFaktaRadPageScripts = ({ story, blok, featuredImage }: ArticleFaktaRadPageScriptsProps) => {
  const ldArticleScript = useSeoArticleScript(story, featuredImage)
  const ldFaqScript = useSeoFaqScript(blok)
  const ldMedicalConditionScript = useSeoMedicalConditionScript(story)
  const ldMedicalWebPageScript = useSeoMedicalWebPageScript(story)
  const ldHowToScript = useSeoHowToScript(story)

  return (
    <>
      {ldArticleScript && (
        <script
          id="articleJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldArticleScript)
          }}
        />
      )}
      {ldFaqScript && (
        <script
          id="articleFaqJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldFaqScript)
          }}
        />
      )}
      {ldMedicalConditionScript && (
        <script
          id="articleMedicalConditionJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldMedicalConditionScript)
          }}
        />
      )}
      {ldMedicalWebPageScript && (
        <script
          id="articleMedicalWebPageJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldMedicalWebPageScript)
          }}
        />
      )}
      {ldHowToScript && (
        <script
          id="articleHowToJsonLd"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ldHowToScript)
          }}
        />
      )}
    </>
  )
}
