import { BlokProps } from "@helpers/StoryblokHelpers"
import { ManagementSectionStoryblok } from "@storyblok-types"
import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"

export const ManagementSection = ({ blok }: BlokProps<ManagementSectionStoryblok>) => {
  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      {blok.title && (
        <Row justifyContent="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="text-left lg:text-center">
            <h1 className="display-header">{blok.title}</h1>
          </Col>
        </Row>
      )}
      <Row>
        {blok.people?.map((person, i) => {
          return (
            <Col grid={{ xs: 12, md: 6, lg: 4, xl: 3 }} className="my-4" key={`${person.name}-${i}`}>
              <StoryblokComponent blok={person} />
            </Col>
          )
        })}
      </Row>
    </GridWrapper>
  )
}
