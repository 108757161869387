"use client"

import { Button } from "@/components/ui-library/button/Button"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { usePreventScroll } from "@/hooks/usePreventScroll"
import { trackEvent } from "@/lib/amplitude/amplitude"
import { updateURLParameters } from "@/lib/updateURLParameters"
import { KollarnaIframeCtaStoryblok } from "@/types/component-types"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"

const KollarnaIframeModal = dynamic(() => import("@ui-library/modal/KollarnaIframeModal"), {
  ssr: false
})

type KollarnaIframeCtaProps = {
  blok: BlokProps<KollarnaIframeCtaStoryblok>["blok"]
  variant?: "primary" | "secondary" | "accent"
}

export const KollarnaIframeCta = ({ blok, variant }: KollarnaIframeCtaProps) => {
  const pathname = usePathname()
  const buttonVariant = variant ? variant : blok.button_variant
  const buttonTracking = blok.button_custom_tracking ?? blok.button_tracking
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [kollarnaUrl, setKollarnaUrl] = useState<string>("")

  const handleClick = () => {
    if (modalOpen) {
      setModalOpen(false)
    } else {
      setModalOpen(true)
      if (buttonTracking) trackEvent(buttonTracking, { page_path: pathname })
    }
  }

  useEffect(() => {
    const defaulUrl = process.env.KOLLARNA_DIAGNOSIS_URL

    const params = {
      ["type"]: blok.kollarna_type,
      ["tracking"]: blok.kollarna_tracking ? blok.kollarna_tracking.toString() : "false",
      ["utm_source"]: blok.kollarna_utm_source || "",
      ["utm_medium"]: blok.kollarna_utm_medium || ""
    }

    const url = updateURLParameters(defaulUrl, params)
    const additionalParam = process.env.DOKTOR_ENVIRONMENT !== "production" ? "&environment=Test" : ""

    setKollarnaUrl(`${url}${additionalParam}`)
  }, [blok])

  usePreventScroll(modalOpen)

  return (
    <>
      <Button variant={buttonVariant} onClick={handleClick}>
        {blok.button_text}
      </Button>
      {modalOpen && <KollarnaIframeModal open={modalOpen} kollarnaUrl={kollarnaUrl} onClose={handleClick} />}
    </>
  )
}

export default KollarnaIframeCta
