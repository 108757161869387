import { BlokProps } from "@helpers/StoryblokHelpers"
import { ButtonLinkStoryblok, FeaturedArticlesStoryblok } from "@storyblok-types"

import ButtonLink from "@/components/blocks/components/ButtonLink"
import { useConfigStore } from "@/store/config-store"
import { useMemo } from "react"

export const DefaultArticlesButtonLink = ({ blok }: BlokProps<FeaturedArticlesStoryblok>) => {
  const { strings } = useConfigStore.getState()

  const hasArticlesButtonLink = useMemo(
    () => !!blok.buttons?.find(block => block.component === "button_link"),
    [blok.buttons]
  )

  const defaultFeaturedArticlesProps: ButtonLinkStoryblok = {
    _uid: "",
    link: {
      cached_url: "artiklar"
    },
    name: strings?.articles.latestArticlesButtonText ?? "",
    variant: "primary_outlined",
    component: "button_link"
  }

  if (hasArticlesButtonLink) {
    return undefined
  }

  return <ButtonLink blok={defaultFeaturedArticlesProps}></ButtonLink>
}

export default DefaultArticlesButtonLink
