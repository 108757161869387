import * as RadixAccordion from "@radix-ui/react-accordion"

import { cn } from "@utils/utils"

export type AccordionProps = RadixAccordion.AccordionSingleProps

export const Accordion = (props: AccordionProps) => {
  return (
    <RadixAccordion.Root className={cn("flex w-full flex-col border-t border-neutral-30")} collapsible {...props} />
  )
}

export default Accordion
