"use client"

import FilterMenu from "@/components/ui-library/menu/FilterMenu"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { FilterTagStory, Location, VaccinationLocation } from "@/types/NonSyncedTypes"
import { VaccinationLocationsListStoryblok } from "@/types/component-types"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Skeleton from "@ui-library/skeleton/Skeleton"
import Fuse from "fuse.js"
import dynamic from "next/dynamic"
import { useCallback, useDeferredValue, useMemo, useRef, useState } from "react"
import VaccinationLocationCard from "./VaccinationLocationCard"

const VaccinationBusMap = dynamic(() => import("./VaccinationBusMap"), {
  ssr: false,
  loading: () => <Skeleton className="h-80 max-w-full overflow-hidden rounded-3xl md:mx-4 lg:h-[420px]" />
})

interface VaccinationLocationListProps {
  blok: BlokProps<VaccinationLocationsListStoryblok>["blok"]
  locations: VaccinationLocation[]
  regionTags: FilterTagStory[]
}

export const VaccinationLocationList = ({ blok, locations, regionTags }: VaccinationLocationListProps) => {
  const fuse = useRef(
    new Fuse<VaccinationLocation>(locations, {
      keys: ["name"],
      threshold: 0,
      ignoreLocation: true
    })
  )

  const [selectedMarker, setSelectedMarker] = useState<Location | null>(null)
  const [filteredLocations, setFilteredLocations] = useState<VaccinationLocation[]>([])
  const deferredLocations = useDeferredValue(filteredLocations)

  const returnedLocations = useMemo(
    () => (deferredLocations.length ? deferredLocations : locations),
    [deferredLocations, locations]
  )

  const filterLocations = (value: string) => {
    const result = fuse.current.search(value)
    setFilteredLocations(result.map(obj => obj.item as VaccinationLocation))
  }

  const filterOnSelectedMarker = useCallback(
    (marker: Location | null) => {
      if (locations) return
      if (marker?.id === selectedMarker?.id) return
      setSelectedMarker(marker)
      if (marker) {
        filterLocations(marker.name)
      } else {
        setFilteredLocations([...locations])
      }
    },
    [locations, selectedMarker]
  )

  return (
    <>
      {returnedLocations && returnedLocations.length > 0 ? (
        <div className="md:mx-4">
          <section className="relative mx-auto mb-4 flex min-h-[360px] w-full max-w-footer flex-wrap items-center overflow-hidden rounded-2xl bg-surface shadow-elevation-1 lg:mb-8">
            {regionTags.length >= 1 && (
              <article className="w-full p-8 lg:max-w-4 lg:p-10 xl:p-20">
                <h4>Välj region</h4>
                <FilterMenu tags={regionTags} placeholder="Välj region" allLabel="Alla regioner" param="region" />
              </article>
            )}

            <VaccinationBusMap
              locations={returnedLocations}
              onSelectMarker={filterOnSelectedMarker}
              className="w-full lg:max-w-8"
            />
          </section>
        </div>
      ) : (
        <article className="flex w-full justify-center px-4">
          <h4>Välj region</h4>
          <FilterMenu
            tags={regionTags}
            placeholder="Välj region"
            allLabel="Alla regioner"
            param="region"
            className="max-w-[320px]"
          />
        </article>
      )}

      <GridWrapper>
        {returnedLocations && returnedLocations.length > 0 ? (
          <Row>
            <Col grid={{ xs: 12 }} className="my-2 text-center lg:my-4">
              <h3>{blok.title}</h3>
            </Col>
            {returnedLocations.map(location => (
              <Col key={location.uuid} grid={{ xs: 12, md: 6, xl: 4 }} className="my-2 lg:my-4">
                <VaccinationLocationCard title={location.name} url={location.url} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col grid={{ xs: 12 }} className="my-2 text-center lg:my-4">
              <h3>Just nu har vi inga Vaccinationsbussar i din region.</h3>
            </Col>
          </Row>
        )}
      </GridWrapper>
    </>
  )
}
export default VaccinationLocationList
