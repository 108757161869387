const HouseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.50824 17.9492C4.2249 17.9492 3.9874 17.8534 3.79574 17.6617C3.60407 17.4701 3.50824 17.2326 3.50824 16.9492V8.57422L2.50824 9.34922C2.2749 9.51589 2.0249 9.58255 1.75824 9.54922C1.49157 9.51589 1.2749 9.38255 1.10824 9.14922C0.941569 8.91588 0.874902 8.66588 0.908236 8.39922C0.941569 8.13255 1.06657 7.91589 1.28324 7.74922L3.50824 6.04922V3.94922C3.50824 3.66589 3.60407 3.42839 3.79574 3.23672C3.9874 3.04505 4.2249 2.94922 4.50824 2.94922C4.79157 2.94922 5.02907 3.04505 5.22074 3.23672C5.4124 3.42839 5.50824 3.66589 5.50824 3.94922V4.52422L10.9082 0.424219C10.9916 0.357552 11.0832 0.303385 11.1832 0.261719C11.2832 0.220052 11.3916 0.199219 11.5082 0.199219C11.6249 0.199219 11.7332 0.220052 11.8332 0.261719C11.9332 0.303385 12.0249 0.357552 12.1082 0.424219L21.7332 7.74922C21.9499 7.91589 22.0749 8.13255 22.1082 8.39922C22.1416 8.66588 22.0749 8.91588 21.9082 9.14922C21.7416 9.36588 21.5249 9.49088 21.2582 9.52422C20.9916 9.55755 20.7499 9.49089 20.5332 9.32422L19.5082 8.57422V16.9492C19.5082 17.2326 19.4124 17.4701 19.2207 17.6617C19.0291 17.8534 18.7916 17.9492 18.5082 17.9492H4.50824ZM5.50824 15.9492H10.5082C10.5082 15.9492 10.5833 15.9492 10.7957 15.9492C11.0082 15.9492 11.2249 15.9492 11.5082 15.9492C11.7916 15.9492 12.0082 15.9492 12.0082 15.9492H17.5082V7.04922L11.5082 2.47422L5.50824 7.04922V15.9492Z"
        fill="currentColor"
      />
      <path
        d="M11.5079 5.94922C11.2453 5.94922 11.0062 6.04242 10.8179 6.23066C10.6297 6.4189 10.5365 6.65801 10.5365 6.92065V9.37779H8.07934C7.8167 9.37779 7.57759 9.47099 7.38935 9.65923C7.20111 9.84747 7.10791 10.0866 7.10791 10.3492C7.10791 10.6119 7.20111 10.851 7.38935 11.0392C7.57759 11.2274 7.8167 11.3206 8.07934 11.3206H10.5365V13.7778C10.5365 14.0404 10.6297 14.2795 10.8179 14.4678C11.0062 14.656 11.2453 14.7492 11.5079 14.7492C11.7705 14.7492 12.0097 14.656 12.1979 14.4678C12.3861 14.2795 12.4793 14.0404 12.4793 13.7778V11.3206H14.9365C15.1991 11.3206 15.4382 11.2274 15.6265 11.0392C15.8147 10.851 15.9079 10.6119 15.9079 10.3492C15.9079 10.0866 15.8147 9.84747 15.6265 9.65923C15.4382 9.47099 15.1991 9.37779 14.9365 9.37779H12.4793V6.92065C12.4793 6.65801 12.3861 6.4189 12.1979 6.23066C12.0097 6.04242 11.7705 5.94922 11.5079 5.94922Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HouseIcon
