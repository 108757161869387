import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import dynamic from "next/dynamic"

interface ChipTagProps {
  text: string
  icon?: string
  className?: string
}

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

const ChipTag = ({ text, icon, className }: ChipTagProps) => {
  return (
    <label
      className={cn(
        "flex w-max items-center gap-2 whitespace-nowrap rounded-lg border border-neutral-30 bg-surface px-4 py-2",
        icon && "py-2 pl-2 pr-4",
        className
      )}
    >
      {icon && <DoktorIcon iconName={icon} width={18} height={18} />}
      <Label size="small" asChild>
        <span>{text}</span>
      </Label>
    </label>
  )
}

export default ChipTag
