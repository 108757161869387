import { ISbStoryData, SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { LatestArticlesStoryblok } from "@storyblok-types"

import ArticleCard from "@ui-library/card/ArticleCard"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"

interface LatestArticlesProps {
  blok: BlokProps<LatestArticlesStoryblok>["blok"]
  latestArticles: ISbStoryData[]
}

export const LatestArticles = ({ blok, latestArticles }: LatestArticlesProps) => {
  if (!latestArticles) return null

  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row justifyContent="center" justifyContentMobile="center">
        <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-8 lg:text-center">
          <h2>{blok.title}</h2>
          <Paragraph size="medium">{blok.content}</Paragraph>
        </Col>

        {latestArticles.map(article => (
          <Col key={article.id} grid={{ xs: 12, lg: 6 }}>
            <ArticleCard article={article} />
          </Col>
        ))}

        {blok.buttons && blok.buttons.length > 0 && (
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mt-12 flex justify-center">
            {blok.buttons?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
          </Col>
        )}
      </Row>
    </GridWrapper>
  )
}

export default LatestArticles
