import { ISbStoryData } from "@storyblok/react/rsc"

import Image from "next/image"
import Link from "next/link"

import { formatDate } from "@lib/formatDate"
import { truncateStringByWords } from "@lib/truncateString"
import { Paragraph } from "@ui-library/typography/Body"

export const PressreleaseCard = ({ pressrelease }: { pressrelease: ISbStoryData }) => {
  const formattedDate = formatDate(pressrelease.content.published_date)
  const preamble = truncateStringByWords(pressrelease.content.preamble, 28)

  return (
    <Link
      href={pressrelease.full_slug}
      className="flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-surface shadow-elevation-1"
    >
      <figure className="relative block h-64 w-full">
        <Image
          src={pressrelease.content.featured_image.filename || ""}
          alt={pressrelease.content.featured_image.alt}
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          style={{ objectFit: "cover", objectPosition: "top center" }}
        />
      </figure>
      <div className="flex min-h-min flex-col justify-between px-8 py-6">
        <h3>{pressrelease.name}</h3>
        <Paragraph size="medium">{preamble}</Paragraph>
      </div>
      <div className="mt-auto px-8 pb-6">
        <Paragraph size="small" asChild className="mb-0 mt-8 text-neutral-50">
          <span>{formattedDate} ・ Pressmeddelande</span>
        </Paragraph>
      </div>
    </Link>
  )
}

export default PressreleaseCard
