import { cn } from "@utils/utils"
import { forwardRef, HTMLAttributes } from "react"

type HeadingProps = HTMLAttributes<HTMLHeadingElement>

export const H1 = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, className, ...props }, ref) => {
  return (
    <h1 className={cn("h1", className)} ref={ref} {...props}>
      {children}
    </h1>
  )
})

export const H2 = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, className, ...props }, ref) => {
  return (
    <h2 className={cn("h2", className)} ref={ref} {...props}>
      {children}
    </h2>
  )
})

export const H3 = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, className, ...props }, ref) => {
  return (
    <h3 className={cn("h3", className)} ref={ref} {...props}>
      {children}
    </h3>
  )
})

export const H4 = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, className, ...props }, ref) => {
  return (
    <h4 className={cn("h4", className)} ref={ref} {...props}>
      {children}
    </h4>
  )
})

export const DisplayHeader = forwardRef<HTMLHeadingElement, HeadingProps>(({ children, className, ...props }, ref) => {
  return (
    <h1 className={cn("display-header", className)} ref={ref} {...props}>
      {children}
    </h1>
  )
})

H1.displayName = "H1"
H2.displayName = "H2"
H3.displayName = "H3"
H4.displayName = "H4"
DisplayHeader.displayName = "DisplayHeader"
