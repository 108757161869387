import { StoryblokComponent } from "@storyblok/react/rsc"

import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { GridSectionStoryblok } from "@storyblok-types"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"

export const GridSection = ({ blok }: BlokProps<GridSectionStoryblok>) => {
  const gridItemSize = {
    xs: blok.columns_size_mobile ?? 12,
    lg: blok.columns_size_tablet ?? 6,
    xl: blok.columns_size_desktop ?? 6
  }

  return (
    <GridWrapper background={blok.background} {...withStoryblokEditable(blok)}>
      {blok.title && (
        <Row justifyContent="center" justifyContentMobile="start">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="lg:text-center">
            <h2>{blok.title}</h2>
          </Col>
        </Row>
      )}
      <Row alignItems={blok.vertical} justifyContent={blok.horizontal}>
        {blok.items?.map(blok => (
          <Col key={blok._uid} grid={gridItemSize}>
            <StoryblokComponent blok={blok} />
          </Col>
        ))}
      </Row>
    </GridWrapper>
  )
}

export default GridSection
