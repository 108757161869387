import { ElementId } from "@/consts/element-id"
import { scrollToElement } from "@/helpers/ScrollToElement"
import { IoCheckmarkCircleOutline } from "react-icons/io5"

export const MedicalApprovedBadge = () => (
  <div
    className="ml-auto mr-0 flex w-fit cursor-pointer flex-row items-center"
    onClick={() => scrollToElement(ElementId.MedialRedactorInfo)}
  >
    <IoCheckmarkCircleOutline /> <span className="ml-2">Medicinskt granskad</span>
  </div>
)
