import { BodySmallStoryblok } from "@storyblok-types"
import { Paragraph } from "@ui-library/typography/Body"

export const BodySmall = ({ content }: BodySmallStoryblok) => {
  return (
    <Paragraph size="small" className="whitespace-pre-wrap">
      {content}
    </Paragraph>
  )
}

export default BodySmall
