const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.15002 19.1C0.900024 18.85 0.775024 18.554 0.775024 18.212C0.775024 17.8707 0.900024 17.575 1.15002 17.325L8.47502 10L1.12502 2.65C0.891691 2.41667 0.775024 2.125 0.775024 1.775C0.775024 1.425 0.900024 1.125 1.15002 0.875C1.40002 0.625 1.69602 0.5 2.03802 0.5C2.37936 0.5 2.67502 0.625 2.92502 0.875L11.325 9.3C11.425 9.4 11.496 9.50833 11.538 9.625C11.5794 9.74167 11.6 9.86667 11.6 10C11.6 10.1333 11.5794 10.2583 11.538 10.375C11.496 10.4917 11.425 10.6 11.325 10.7L2.90002 19.125C2.66669 19.3583 2.37936 19.475 2.03802 19.475C1.69602 19.475 1.40002 19.35 1.15002 19.1Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronRightIcon
