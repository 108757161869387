import Link from "next/link"

import ArrowForwardIcon from "@layout/icons/ArrowForwardIcon"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"
import { HTMLAttributes } from "react"

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  url?: string | unknown
  target?: string
  text: string
  color?: string
  className?: string
  onClick?: () => void
}

const ArrowLink = ({ text, url, color, className, onClick, ...rest }: LinkProps) => {
  return (
    <Link
      href={url || "#"}
      className={cn("box-border inline-flex cursor-pointer whitespace-nowrap no-underline", `text-${color}`, className)}
      onClick={onClick}
      {...rest}
    >
      <span className="flex cursor-pointer items-center gap-2 text-center">
        <Label size="medium" className="cursor-pointer">
          {text}
        </Label>
        {typeof url === "string" && <ArrowForwardIcon />}
      </span>
    </Link>
  )
}

export default ArrowLink
