const ListItemIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.3226 6.96069L9.63305 13.2782L6.86777 10.3237C6.53972 9.99566 6.0889 10.1426 5.97821 10.2291C5.73267 10.4183 5.6884 10.8671 6.01645 11.1952L9.04135 14.4274C9.20638 14.5924 9.42173 14.6749 9.63708 14.6749C9.85242 14.6749 10.0678 14.5944 10.2328 14.4274L16.4315 7.52622C16.5261 7.2183 16.5825 7.02509 16.2605 6.74534C15.8459 6.47566 15.6486 6.62861 15.3226 6.95868V6.96069Z"
        fill="#5F3658"
      />
      <path
        d="M11.2073 0.204468C5.42318 0.204468 0.717773 4.90786 0.717773 10.694C0.717773 16.4802 5.42117 21.1836 11.2073 21.1836C16.9935 21.1836 21.6969 16.4802 21.6969 10.694C21.6969 4.90786 16.9935 0.204468 11.2073 0.204468ZM11.1912 20.1471C5.98067 20.1471 1.73815 15.9086 1.73815 10.694C1.73815 5.47944 5.98268 1.24095 11.1932 1.24095C16.4038 1.24095 20.6463 5.47944 20.6463 10.694C20.6463 15.9086 16.4038 20.1471 11.1932 20.1471H11.1912Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ListItemIcon
