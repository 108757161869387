import GridWrapper from "@/components/ui-library/grid/Wrapper"
import { BlokProps } from "@/helpers/StoryblokHelpers"
import { FullwidthImageSectionStoryblok } from "@/types/component-types"
import Image from "next/image"

export const FullwidthImageSection = ({ blok }: BlokProps<FullwidthImageSectionStoryblok>) => {
  if (!blok.image?.filename) return null
  return (
    <GridWrapper>
      <figure className="relative order-2 mx-auto mb-4 aspect-[1.6] w-full max-w-footer overflow-hidden rounded-2xl lg:order-3 lg:mb-0 lg:mt-8 lg:aspect-[3] xl:aspect-[3.6]">
        {blok.image.filename && (
          <Image src={blok.image.filename} sizes="100vw" alt={blok.image.alt || ""} fill className="object-cover" />
        )}
      </figure>
    </GridWrapper>
  )
}

export default FullwidthImageSection
