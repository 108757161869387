import { Paragraph } from "@ui-library/typography/Body"
import Image from "next/image"

interface ImageTextSectionProps {
  content: string
  image: {
    filename: string
    alt?: string
  }
}

export const ImageTextSection = ({ content, image }: ImageTextSectionProps) => {
  return (
    <div className="flex flex-col whitespace-break-spaces break-words break-all lg:px-8">
      <div className="pb-6 pl-6 lg:pb-8 lg:pl-8">
        <Image src={image.filename || ""} alt={image.alt || ""} width="53" height="84" />
      </div>
      <Paragraph size="small">{content}</Paragraph>
    </div>
  )
}

export default ImageTextSection
