import { ReactNode } from "react"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"

interface CardSectionProps {
  title: string
  content?: string
  cards?: ReactNode[]
  wide?: boolean
}

const CardSection = ({ title, content, cards, wide }: CardSectionProps) => {
  const grid = {
    xs: 12,
    lg: 6,
    xl: wide ? 3 : 4
  }

  return (
    <>
      <GridWrapper>
        <Row justifyContent="center" justifyContentMobile="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="mb-6 text-left lg:mb-10 lg:text-center">
            <h2>{title}</h2>
            {content && <Paragraph size="medium">{content}</Paragraph>}
          </Col>
        </Row>
        <Row justifyContent="center" justifyContentMobile="center">
          {cards?.map((card, index) => (
            <Col grid={grid} key={index}>
              {card}
            </Col>
          ))}
        </Row>
      </GridWrapper>
    </>
  )
}

export default CardSection
