import ChevronRightIcon from "@layout/icons/ChevronRightIcon"
import { defaultSbParams } from "@lib/defaultSbParams"
import { getStoryblokApi, ISbStoriesParams, ISbStory } from "@storyblok/react/rsc"
import Skeleton from "@ui-library/skeleton/Skeleton"
import Link from "next/link"
import { useEffect, useState } from "react"

import { truncateString } from "@lib/truncateString"
import { urlParser } from "@lib/urlParser"
import { Paragraph } from "@ui-library/typography/Body"

type BreadcrumbItemProps = {
  item: string
  current: boolean
  index: number
  breadcrumbCount: number
}

export const BreadcrumbItem = ({ item, current, index, breadcrumbCount }: BreadcrumbItemProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [label, setLabel] = useState<string>("")
  const [url, setUrl] = useState<string>("")
  const [isCurrent, setIsCurrent] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const getBreadcrumbData = async (slug: string) => {
    const sbParams: ISbStoriesParams = {
      ...defaultSbParams
    }

    const storyblokApi = getStoryblokApi()
    const { data } = (await storyblokApi.get(`cdn/stories/${slug}`, sbParams)) as ISbStory

    setLabel(data.story.name)
    setUrl(data.story.full_slug)
    setIsLoading(false)
  }

  useEffect(() => {
    getBreadcrumbData(item)
    setIsCurrent(current)
    setCurrentIndex(index)
  }, [item, current, index])

  if (isLoading) {
    return (
      <>
        <Skeleton className="h-6 w-28" />
        {!isCurrent && <ChevronRightIcon className="mx-3" width={6} />}
      </>
    )
  }

  if (isCurrent)
    return (
      <Paragraph size="small" asChild>
        <span>{truncateString(label, 25)}</span>
      </Paragraph>
    )

  return (
    <>
      {breadcrumbCount > 2 && currentIndex === 0 ? (
        <Paragraph size="small" asChild>
          <span>...</span>
        </Paragraph>
      ) : (
        <Paragraph size="small" asChild className="shrink-0 transition-colors hover:text-on-background">
          <Link href={urlParser(url)}>{truncateString(label, 25)}</Link>
        </Paragraph>
      )}
      <ChevronRightIcon className="mx-3 shrink-0" width={6} />
    </>
  )
}

export default BreadcrumbItem
