import { cn, findColorName } from "@utils/utils"

export const PageContainer = ({
  children,
  background,
  extraHeight,
  className
}: {
  children: React.ReactNode
  background?: string
  extraHeight?: boolean
  className?: string
}) => {
  const bg = findColorName(background)
  return (
    <main
      className={cn(
        "w-full pb-4 pt-16 xl:pt-20",
        bg === "surface" && "bg-surface",
        extraHeight && "pt-24 xl:pt-28",
        className
      )}
    >
      {children}
    </main>
  )
}

export default PageContainer
