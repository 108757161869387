"use client"

import { useEffect, useState } from "react"

import { FindHereData } from "@/components/storyblok-routes/clinics/MapsSection"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"
import dynamic from "next/dynamic"
import { FindHereDesktop } from "./FindHereDesktop"
import { FindHereMobile } from "./FindHereMobile"

const Map = dynamic(() => import("@ui-library/google-map/Map"), {
  ssr: false
})

interface FindHereProps {
  data: FindHereData
  children: React.ReactNode
}

export const FindHere = ({ data, children }: FindHereProps) => {
  const [showMap, setShowMap] = useState<boolean>(false)

  useEffect(() => {
    if (data.lat && data.lng) setShowMap(true)
  }, [data])

  return (
    <GridWrapper>
      <Row>
        <Col grid={{ xs: 12 }} className="text-left lg:text-center">
          <h2 className="mb-6 lg:mb-10">{data.title}</h2>
          {showMap && data.lat && data.lng && (
            <div className="relative mb-6 h-48 overflow-hidden rounded-3xl border border-neutral-30 lg:mb-10 lg:h-96">
              <Map lat={data.lat} lng={data.lng} />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col grid={{ xs: 12, lg: 4 }}>
          <Paragraph size="medium" className="whitespace-break-spaces">
            {data.address}
          </Paragraph>
          <div className="mb-6">{children}</div>
        </Col>
        <Col grid={{ xs: 12, lg: 8 }}>
          <FindHereDesktop {...data} />
          <FindHereMobile {...data} />
        </Col>
      </Row>
    </GridWrapper>
  )
}
