"use client"

import { Label } from "@/components/ui-library/typography/Labels"
import { urlParser } from "@/lib/urlParser"
import { BlokProps } from "@helpers/StoryblokHelpers"
import RightArrow from "@layout/icons/RightArrow"
import { DeepLinkStoryblok } from "@storyblok-types"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import Link from "next/link"

export const DeepLink = ({ blok }: BlokProps<DeepLinkStoryblok>) => (
  <Link
    href={urlParser(blok.link?.cached_url) || ""}
    className="flex h-full flex-col justify-between rounded-2xl bg-surface p-6 text-on-surface shadow-elevation-1"
    {...storyblokEditable(blok as SbBlokData)}
  >
    <h3>{blok.title}</h3>
    <div className="inline-flex items-center justify-start text-primary-50">
      <Label size="medium">{blok.link_title}</Label>
      <RightArrow className="ml-2" />
    </div>
  </Link>
)

export default DeepLink
