"use client"

import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import { isMobile } from "react-device-detect"

import Image from "next/image"
import Link from "next/link"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { ConfigStoryblok, GetTheAppStoryblok } from "@storyblok-types"

const DoktorLogotypeWatermark = dynamic(() => import("@layout/img/DoktorLogotypeWatermark"), { ssr: true })
const AppStore = dynamic(() => import("@layout/icons/AppStore"), { ssr: true })
const GooglePlay = dynamic(() => import("@layout/icons/GooglePlay"), { ssr: true })

import {
  digitalCareGetTheAppSectionDownloadAppAndroidEvent,
  digitalCareGetTheAppSectionDownloadAppIosEvent
} from "@lib/amplitude/helpers"

import { useConfigStore } from "@/store/config-store"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import Richtext from "@ui-library/richtext/Richtext"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"

interface GetTheAppProps {
  blok: BlokProps<GetTheAppStoryblok>["blok"]
}

export const GetTheApp = ({ blok }: GetTheAppProps) => {
  const pathname = usePathname()
  const config = useConfigStore.getState().config as ConfigStoryblok

  const appLinks = [
    {
      url: config?.app_store_link || "",
      icon: <AppStore />,
      label: "Ladda ner vår app via App store",
      trackingEvent: () => digitalCareGetTheAppSectionDownloadAppIosEvent(pathname)
    },
    {
      url: config?.google_play_link || "",
      icon: <GooglePlay />,
      label: "Ladda ner vår app via Google Play",
      trackingEvent: () => digitalCareGetTheAppSectionDownloadAppAndroidEvent(pathname)
    }
  ]

  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row alignItems="center">
        <Col grid={{ xs: 12, lg: 6 }} className="lg:pr-8">
          <h2>{blok.title}</h2>
          <Richtext content={blok.content} />

          {appLinks && (
            <div className={cn("mt-8 hidden items-start", isMobile && "flex")}>
              {appLinks.map((app, idx) => (
                <Link href={app.url || ""} target="_blank" className="mb-6 mr-6" key={idx} onClick={app.trackingEvent}>
                  {app.icon}
                  <span className="sr-only">{app.label}</span>
                </Link>
              ))}
            </div>
          )}
          {config.download_app_qr && (
            <>
              {blok.qr_copy && (
                <Paragraph size="medium" className={cn("!mt-4 hidden", !isMobile && "block")}>
                  {blok.qr_copy}
                </Paragraph>
              )}
              <Image
                src={config?.download_app_qr?.filename || ""}
                alt="Skanna koden för att ladda ner appen"
                width={100}
                height={100}
                className={cn("hidden", !isMobile && "block")}
              />
            </>
          )}
        </Col>

        {blok.image?.filename && (
          <Col grid={{ xs: 12, lg: 6 }} className="lg:pr-8">
            <figure
              className={cn("relative mt-8 w-full overflow-hidden rounded-2xl py-8", blok.show_watermark && "py-0")}
            >
              <Image
                className={cn(
                  "mx-auto my-0 block",
                  blok.show_watermark &&
                    "absolute left-1/2 top-1/2 max-h-[90%] w-auto -translate-x-1/2 -translate-y-1/2"
                )}
                src={blok.image?.filename || ""}
                alt={blok.image?.alt || ""}
                width={227}
                height={453}
              />
              {blok.show_watermark && <DoktorLogotypeWatermark className="text-neutral-10" />}
            </figure>
          </Col>
        )}
      </Row>
    </GridWrapper>
  )
}

export default GetTheApp
