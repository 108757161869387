import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { NewsSectionStoryblok } from "@storyblok-types"

import CarouselSection from "@ui-library/carousel/CarouselSection"

export const NewsSection = ({ blok }: BlokProps<NewsSectionStoryblok>) => {
  return (
    <CarouselSection
      title={blok.title}
      content={blok.content}
      background={blok.background}
      cards={(blok.news || []).map(newsCard => (
        <StoryblokComponent key={newsCard._uid} blok={newsCard} />
      ))}
      {...storyblokEditable(blok as SbBlokData)}
    />
  )
}

export default NewsSection
