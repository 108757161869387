import Image from "next/image"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { LogotypesSectionStoryblok } from "@storyblok-types"

import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { cn } from "@utils/utils"

export const LogotypesSection = ({ blok }: BlokProps<LogotypesSectionStoryblok>) => {
  return (
    <GridWrapper {...storyblokEditable(blok as SbBlokData)}>
      <Row>
        <Col grid={{ xs: 12 }} className="text-center">
          <h2 className="h2">{blok.title}</h2>
        </Col>
      </Row>
      <Row alignItems="center" alignItemsMobile="center" className="lg:mt-8">
        {blok.logotypes?.map((logo, index) => (
          <Col
            key={logo.id}
            grid={{ xs: 6, md: 4 }}
            className={cn(
              { "md:text-left": (index - 0) % 3 === 0 },
              { "md:text-center": (index - 1) % 3 === 0 },
              { "md:text-right": (index - 2) % 3 === 0 }
            )}
          >
            <figure className="inline-block max-h-32 w-full max-w-[250px] px-4 py-4">
              <Image
                src={logo.filename || ""}
                alt={logo.alt || ""}
                width={250}
                height={120}
                className="mx-auto inline-block h-auto max-h-16 w-full max-w-[250px] md:max-h-32"
              />
            </figure>
          </Col>
        ))}
      </Row>
    </GridWrapper>
  )
}

export default LogotypesSection
