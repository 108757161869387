"use client"

import { useEffect, useState } from "react"

import LinkIcon from "@layout/icons/LinkIcon"
import ShareIcon from "@layout/icons/ShareIcon"
import { Button } from "@ui-library/button/Button"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"

const Tooltip = ({ visible, text }: { visible: boolean; text: string }) => {
  return (
    <div
      className={cn(
        "pointer-events-none absolute left-2/4 top-0 -translate-x-2/4 -translate-y-full rounded-lg bg-surface px-6 py-2 opacity-0 drop-shadow-lg transition-all",
        "before:absolute before:-bottom-1 before:left-2/4 before:h-4 before:w-4 before:-translate-x-2/4 before:rotate-45 before:bg-surface before:content-['']",
        visible && "-top-4 opacity-100"
      )}
    >
      <Label size="xsmall">{text}</Label>
    </div>
  )
}

export const ShareArticle = ({ articleTitle }: { articleTitle: string | undefined }) => {
  const [useShareMethod, setUseShareMethod] = useState<boolean>(false)
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

  useEffect(() => {
    setUseShareMethod(Boolean(navigator.share))
  }, [])

  const handleShareClick = () => {
    const shareUrl = window.location.href

    if (navigator.share) {
      navigator
        .share({
          title: articleTitle,
          url: shareUrl
        })
        .catch(error => console.error("Error sharing: ", error))
    } else {
      const dummyInput = document.createElement("input")
      dummyInput.value = shareUrl
      document.body.appendChild(dummyInput)
      dummyInput.select()
      document.execCommand("copy")
      document.body.removeChild(dummyInput)
      setTooltipVisible(true)

      setTimeout(() => {
        setTooltipVisible(false)
      }, 1500)
    }
  }

  return (
    <div className="mb-6 flex w-full items-center justify-between border-b border-neutral-30 pb-4">
      <Label size="medium">Tipsa och dela artikeln</Label>

      <Button variant="primary_outlined" className="relative" onClick={handleShareClick}>
        <Tooltip visible={useShareMethod ? false : tooltipVisible} text="Kopierad" />
        {useShareMethod ? "Dela" : "Kopiera länk"}
        {useShareMethod ? <ShareIcon className="ml-3" /> : <LinkIcon className="ml-3" />}
      </Button>
    </div>
  )
}

export default ShareArticle
