import * as Accordion from "@radix-ui/react-accordion"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { BoardMemberStoryblok } from "@storyblok-types"
import Image from "next/image"

import { Label } from "@/components/ui-library/typography/Labels"
import ChevronDownIcon from "@layout/icons/ChevronDownIcon"
import Richtext from "@ui-library/richtext/Richtext"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import React from "react"

export const BoardMember = ({ blok }: BlokProps<BoardMemberStoryblok>) => {
  return (
    <article className="flex flex-wrap border-b border-neutral-30 py-4 first:border-t">
      <figure className="relative mb-4 aspect-[1.1] w-full max-w-12 md:mb-0 md:max-w-5">
        {blok.photo?.filename && (
          <Image src={blok.photo?.filename || ""} fill alt={blok.name || ""} className="object-cover" />
        )}
      </figure>

      <div className="w-full max-w-12 md:max-w-7 lg:pl-6">
        <h3>{blok.name}</h3>
        {blok.born && <Paragraph size="small">{`Född: ${blok.born}`}</Paragraph>}
        {blok.information && <Paragraph size="small">{blok.information}</Paragraph>}
      </div>

      {!blok.hide_content && (
        <Accordion.Root type="single" collapsible>
          <Accordion.Item className="AccordionItem" value="times">
            <AccordionTrigger className="pt-4 text-primary-50">
              <Label size="medium" asChild>
                <span>Mer information</span>
              </Label>
            </AccordionTrigger>
            <Accordion.Content className="w-full pb-4">
              <Richtext content={blok.content} bodySmall />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      )}
    </article>
  )
}

export default BoardMember

type AccordionTriggerProps = {
  children: React.ReactNode
  className?: string
}

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Trigger
      className={cn(
        "group/trigger flex w-full cursor-pointer items-center justify-start border-0 bg-transparent text-left lg:my-0",
        className
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
      <figure className="m-0 flex h-6 w-6 items-center justify-center">
        <ChevronDownIcon
          height={24}
          aria-hidden
          className="transition-transform ease-out group-aria-expanded/trigger:rotate-180"
        />
      </figure>
    </Accordion.Trigger>
  )
)

AccordionTrigger.displayName = "AccordionTrigger"
