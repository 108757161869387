import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import Image from "next/image"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { SeekCareStartpageStoryblok } from "@storyblok-types"

import Col from "@ui-library/grid/Col"
import Row from "@ui-library/grid/Row"
import GridWrapper from "@ui-library/grid/Wrapper"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"

export const SeekCareStartpage = ({ blok }: BlokProps<SeekCareStartpageStoryblok>) => {
  const blokCards = [
    {
      title: null,
      image: { filename: blok.seek_care_image?.filename, alt: blok.seek_care_image?.alt },
      text: blok.seek_care_text,
      buttons: blok.seek_care_buttons,
      custom: false
    },
    {
      title: blok.health_center_title,
      image: { filename: blok.health_center_image?.filename, alt: blok.health_center_image?.alt },
      text: blok.health_center_text,
      buttons: blok.health_center_buttons,
      custom: true
    }
  ]

  return (
    <>
      <GridWrapper
        className="lg: mx-0 my-0 rounded-none py-0 md:mx-4 md:py-16 lg:rounded-3xl lg:bg-surface"
        {...storyblokEditable(blok as SbBlokData)}
      >
        <Row justifyContent="center" justifyContentMobile="center">
          <Col grid={{ xs: 12, lg: 10, xl: 8 }} className="my-0 lg:text-center">
            <h2>{blok.title}</h2>
          </Col>
        </Row>

        {blokCards && (
          <Row>
            {blokCards.map((card, idx) => (
              <Col
                grid={{ xs: 12, lg: 6 }}
                key={idx}
                className={cn(card.custom && "bg-surface max-lg:mb-0 lg:bg-transparent")}
              >
                <article className="h-full w-full lg:overflow-hidden lg:rounded-2xl lg:bg-surface lg:shadow-elevation-1">
                  {card.title && <h2 className="mt-12 lg:hidden">{card.title}</h2>}
                  <figure className="relative aspect-[2] w-full overflow-hidden rounded-2xl lg:rounded-none">
                    <Image
                      src={card.image.filename || ""}
                      width={610}
                      height={0}
                      sizes="(max-width: 768px) 100vw, 50vw"
                      alt={card.image.alt || ""}
                      className="absolute inset-0 h-full w-full object-cover object-top"
                    />
                  </figure>
                  <Paragraph size="medium" className="py-6 lg:pl-6 lg:pr-12">
                    {card.text}
                  </Paragraph>
                  {card.buttons && (
                    <div className="flex w-full flex-wrap justify-between lg:px-6 lg:pb-6 [&>a]:mb-4 [&>a]:w-full [&>a]:max-w-full [&>a]:justify-center">
                      {card.buttons?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
                    </div>
                  )}
                </article>
              </Col>
            ))}
          </Row>
        )}

        {blok.chips && (
          <Row className="bg-surface pb-12 lg:bg-transparent lg:py-8">
            <Col grid={{ xs: 12 }} className="lg:text-center">
              <h2 className="my-8">{blok.chips_title}</h2>

              <div className="flex w-full flex-wrap gap-2 lg:justify-center">
                {blok.chips?.map(blok => <StoryblokComponent key={blok._uid} blok={blok} />)}
              </div>
            </Col>
          </Row>
        )}
      </GridWrapper>
    </>
  )
}

export default SeekCareStartpage
