import { ISbStoryData } from "@storyblok/react/rsc"
import ArticleCard from "@ui-library/card/ArticleCard"
import FeaturedArticleCard from "@ui-library/card/FeaturedArticleCard"
import Col from "@ui-library/grid/Col"

type ArticleListProps = {
  currentPage?: number
  articles: ISbStoryData[]
}

const ArticleList = ({ currentPage, articles }: ArticleListProps) => {
  return (
    <>
      {articles.map((article, i) => {
        const isFeatured = i <= 1 && currentPage === 1
        const isInverted = isFeatured && i === 0 && currentPage === 1
        const grid = {
          xs: 12,
          lg: isFeatured ? 12 : 6
        }

        return (
          <Col key={article.id} grid={grid} className="mt-4 lg:mt-8">
            {isFeatured ? (
              <FeaturedArticleCard article={article} inverted={isInverted} />
            ) : (
              <ArticleCard article={article} />
            )}
          </Col>
        )
      })}
    </>
  )
}

export default ArticleList
