import { BlokProps } from "@helpers/StoryblokHelpers"
import { IconComponentStoryblok } from "@storyblok-types"

import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import Richtext from "@ui-library/richtext/Richtext"
import dynamic from "next/dynamic"

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), {
  ssr: true
})

export const IconComponent = ({ blok }: BlokProps<IconComponentStoryblok>) => {
  return (
    <div
      className="my-4 flex flex-row flex-wrap items-center lg:flex-col lg:items-start"
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.icon && (
        <figure className="flex h-16 w-16 items-center justify-center lg:h-24 lg:w-24">
          <DoktorIcon
            iconName={blok.icon}
            width={70}
            height={70}
            className="block h-auto max-h-[36px] w-auto max-w-[36px] text-primary-50 lg:max-h-[70px] lg:max-w-[70px]"
          />
        </figure>
      )}
      {blok.content && (
        <div className="w-icon pr-8 lg:w-full lg:pr-4">
          <Richtext content={blok.content} />
        </div>
      )}
    </div>
  )
}

export default IconComponent
