import { BlokProps, withStoryblokEditable } from "@helpers/StoryblokHelpers"
import { ContactPageOfferSectionStoryblok } from "@storyblok-types"

import CardSection from "@ui-library/card/CardSection"
import ImageTextSection from "@ui-library/section/ImageTextSection"

export const ContactPageOfferSection = ({ blok }: BlokProps<ContactPageOfferSectionStoryblok>) => {
  return (
    <CardSection
      wide={true}
      {...withStoryblokEditable(blok)}
      title={blok.heading}
      cards={blok.sections?.map(section => (
        <ImageTextSection
          key={section._uid}
          content={section.content}
          image={{ filename: section.image.filename, alt: section.image.alt }}
        />
      ))}
    />
  )
}

export default ContactPageOfferSection
