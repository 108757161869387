import { FactAdviceListItem } from "@/types/NonSyncedTypes"
import Col from "@ui-library/grid/Col"
import { Fragment } from "react"
import ListItem from "./ListItem"

export const ItemList = ({ stories }: { stories: FactAdviceListItem[] }) => {
  return (
    <>
      {stories.map(story => (
        <Fragment key={story.id}>
          {story.start_page && (
            <Col grid={{ xs: 12, lg: 6, xl: 4 }} key={story.id} className="my-0 lg:mb-12">
              <ListItem story={story} />
            </Col>
          )}
        </Fragment>
      ))}
    </>
  )
}

export default ItemList
