import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { FaqStoryblok } from "@storyblok-types"

import AccordionItem from "@ui-library/accordion/AccordionItem"
import Richtext from "@ui-library/richtext/Richtext"

export const FAQ = ({ blok }: BlokProps<FaqStoryblok>) => {
  return (
    <AccordionItem title={blok.question} value={blok._uid} {...storyblokEditable(blok as SbBlokData)}>
      <Richtext content={blok.answer} />
    </AccordionItem>
  )
}

export default FAQ
