"use client"

import * as Accordion from "@radix-ui/react-accordion"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"

import Link from "next/link"

import { useWindowResize } from "@/hooks/useWindowResize"
import { removeTrailingSlash } from "@/lib/removeTrailingSlash"
import { FactAdviceListItem } from "@/types/NonSyncedTypes"
import ArrowForwardIcon from "@layout/icons/ArrowForwardIcon"
import ChevronDownIcon from "@layout/icons/ChevronDownIcon"
import { Paragraph } from "@ui-library/typography/Body"
import { cn } from "@utils/utils"
import dynamic from "next/dynamic"

const DoktorIcon = dynamic(() => import("@layout/DoktorIcons"), { ssr: true })

interface ListItemProps {
  story: FactAdviceListItem
}

export const ListItem = ({ story }: ListItemProps) => {
  const [isOpen, setIsState] = useState<true | undefined>(true)
  const AccordionRef = useRef<HTMLDivElement>(null)

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth < 767) {
      setIsState(undefined)
    } else {
      setIsState(true)
    }
  }, [])

  useWindowResize(handleWindowResize)

  useEffect(() => {
    handleWindowResize()
  }, [story.name, handleWindowResize])

  const storyUrl = removeTrailingSlash(story.url)

  return (
    <Accordion.Root className="w-full border-t border-neutral-30 lg:border-t-0" type="single" collapsible>
      <Accordion.Item value={story.name}>
        <AccordionTrigger>
          <h3 className={cn("m-0 flex w-full items-center justify-between")} ref={AccordionRef}>
            <figure className="relative flex aspect-square w-8 items-center justify-center rounded-full bg-primary-50 text-surface lg:w-10">
              {story.header_icon && (
                <DoktorIcon iconName={story.header_icon} width={28} height={28} className="block max-h-[66%] max-w-8" />
              )}
            </figure>

            <Link className="mr-auto pl-4 pr-4" href={storyUrl}>
              {story.name}
            </Link>
          </h3>
        </AccordionTrigger>

        {story.children && (
          <Accordion.Content className="w-full pl-12 pr-1 lg:pl-14 lg:pr-12 lg:pt-6" forceMount={isOpen}>
            {story.children?.map(child => {
              if (child.start_page) return
              return (
                <Link
                  key={child.id}
                  href={`/${child.url}`}
                  prefetch={false}
                  className="relative mb-4 flex items-center justify-between p-0 pr-8 last:mb-0 hover:origin-center hover:scale-[1.01] hover:transition-transform"
                >
                  <Paragraph size="small" asChild>
                    <span>{child.name}</span>
                  </Paragraph>
                  <ArrowForwardIcon className="absolute right-0 text-primary-50" />
                </Link>
              )
            })}
          </Accordion.Content>
        )}
      </Accordion.Item>
    </Accordion.Root>
  )
}

export default ListItem

type AccordionTriggerProps = {
  children: ReactNode
  className?: string
}

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Trigger
      className={cn(
        "group/trigger my-4 flex w-full cursor-pointer justify-center border-0 bg-transparent text-left lg:my-0",
        className
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
      <figure className="m-0 flex h-6 w-6 items-center justify-center lg:hidden">
        <ChevronDownIcon
          height="24"
          aria-hidden
          className="transition-transform ease-out group-aria-expanded/trigger:rotate-180"
        />
      </figure>
    </Accordion.Trigger>
  )
)
AccordionTrigger.displayName = "AccordionTrigger"
