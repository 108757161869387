import { cn, findColorName } from "@utils/utils"
import { HTMLAttributes, forwardRef } from "react"

interface GridWrapperProps extends HTMLAttributes<HTMLDivElement> {
  background?: string
}

const GridWrapper = forwardRef<HTMLDivElement, GridWrapperProps>(
  ({ children, className, background = "#FBF5F0", ...props }, ref) => {
    const backgroundColor = findColorName(background)

    return (
      <section
        ref={ref}
        className={cn(
          `my-12 rounded-3xl md:my-16 bg-${backgroundColor}`,
          { "mx-0 py-12 md:mx-4 md:py-16": backgroundColor === "surface" },
          { "px-0": backgroundColor === "background" },
          className
        )}
        {...props}
      >
        {children}
      </section>
    )
  }
)

GridWrapper.displayName = "GridWrapper"

export default GridWrapper
