import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { CtaSectionStoryblok } from "@storyblok-types"

import { CTABlock } from "@/components/ui-library/cta/CtaBlock"
import { cn } from "@/utils/utils"
import { Paragraph } from "@ui-library/typography/Body"

export const CtaSection = ({ blok }: BlokProps<CtaSectionStoryblok>) => {
  const blokId = `section-${blok._uid}`
  const blokVariant = blok.card_version || "secondary"

  return (
    <CTABlock
      variant={blokVariant}
      id={blokId}
      alignment={blok.center_content ? "center" : "default"}
      elevated={blok.add_elevation ? "elevated" : "default"}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.title && <h3>{blok.title}</h3>}
      {blok.text && <Paragraph size="small">{blok.text}</Paragraph>}
      {blok.button_link && blok.button_link.length > 0 && (
        <div
          className={cn(
            "flex w-full flex-wrap items-start justify-start gap-4",
            blok.center_content && "justify-center"
          )}
        >
          {blok.button_link?.map((blok, i) => (
            <StoryblokComponent
              key={blok._uid}
              blok={blok}
              variant={i === 0 ? blokVariant : `${blokVariant}_outlined`}
            />
          ))}
        </div>
      )}
    </CTABlock>
  )
}

export default CtaSection
