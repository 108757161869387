"use client"

import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"
import WarningCloseIcon from "@layout/icons/WarningCloseIcon"
import Link from "next/link"
import React from "react"
import { Paragraph } from "../typography/Body"
import { Label } from "../typography/Labels"

const MessageVariants = cva(
  "flex flex-wrap justify-between w-full items-center rounded-lg px-4 py-3 relative pt-12 lg:pt-3 lg:pr-12",
  {
    variants: {
      variant: {
        information: "bg-success-10 text-on-success-10",
        warning: "bg-caution-10 text-on-caution-10",
        error: "bg-warning-10 text-on-warning-10"
      }
    },
    defaultVariants: {
      variant: "information"
    }
  }
)

export interface MessageProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof MessageVariants> {
  message?: string
  buttonText?: string
  buttonUrl?: string
  onClose: () => void
}

const Message = React.forwardRef<HTMLDivElement, MessageProps>(
  ({ className, variant, message, buttonText, buttonUrl, onClose, ...props }, ref) => {
    return (
      <figure className={cn(MessageVariants({ variant, className }))} ref={ref} {...props}>
        <Paragraph size="small" className="w-full max-w-full lg:max-w-3xl" asChild>
          <span>{message}</span>
        </Paragraph>
        {buttonText && buttonUrl && (
          <Link href={buttonUrl} className="mt-4 lg:mt-0">
            <Label size="medium">{buttonText}</Label>
          </Link>
        )}

        <button className="absolute right-4 top-4 lg:top-2/4 lg:-translate-y-2/4" onClick={onClose}>
          <WarningCloseIcon />
        </button>
      </figure>
    )
  }
)

Message.displayName = "Message"

export { Message, MessageVariants }
