import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc"

import { BlokProps } from "@helpers/StoryblokHelpers"
import { NewsItemStoryblok } from "@storyblok-types"

import CarouselCard from "@ui-library/carousel/CarouselCard"

export const NewsItem = ({ blok }: BlokProps<NewsItemStoryblok>) => {
  return (
    <CarouselCard
      heading={blok.heading}
      content={blok.content}
      link={blok.arrow_link?.map(link => <StoryblokComponent key={link._uid} blok={link} variant="secondary" />)}
      {...storyblokEditable(blok as SbBlokData)}
    />
  )
}

export default NewsItem
