import Link from "next/link"

import { Paragraph } from "@/components/ui-library/typography/Body"
import MapIcon from "@layout/icons/MapIcon"

interface IVaccinationLocationCardProps {
  title?: string
  url?: string
}

const VaccinationLocationCard = ({ title, url }: IVaccinationLocationCardProps) => {
  return (
    <Link
      href={url || "#"}
      className="block rounded-lg bg-surface p-6 shadow-elevation-1 lg:py-12 lg:text-center lg:shadow-none"
    >
      <h3 className="m-0 mb-2 lg:text-primary-70">{title}</h3>
      <div className="mt-2 inline-flex items-center">
        <MapIcon className="mr-2" />
        <Paragraph size="small" asChild>
          <span>Se tider & Platser</span>
        </Paragraph>
      </div>
    </Link>
  )
}

export default VaccinationLocationCard
