import { PriceDataProps } from "@blocks/templates/VaccinationBusLocationPage"
import React from "react"

const VaccinationPriceTable = ({ tableData }: { tableData: PriceDataProps[] }) => {
  return (
    <table className="mb-8 w-full table-fixed">
      <tbody>
        {tableData.map((data, idx) => (
          <React.Fragment key={idx}>
            {data.price && (
              <tr className="border-b border-b-neutral-30 last:border-b-0">
                <td className="max-w-4 py-2">
                  <h4 className="m-0">{data.label}</h4>
                </td>
                <td className="max-w-8 py-2">
                  <h4 className="m-0">{data.price}</h4>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default VaccinationPriceTable
