import ListItemIcon from "@layout/icons/ListItemIcon"
import { Label } from "@ui-library/typography/Labels"
import { cn } from "@utils/utils"

interface ListItemProps {
  title: string
  content?: string
  className?: string
}

const ListItem = ({ title, content, className }: ListItemProps) => {
  return (
    <div className={cn("mb-4 flex gap-4 text-on-surface last:mb-0", className)}>
      <figure className="h-8 w-8 p-1 text-secondary-50">
        <ListItemIcon />
      </figure>
      <div className="flex flex-col">
        <Label size="medium">{title}</Label>
        <Label className="text-on-neutral-10" size="medium">
          {content}
        </Label>
      </div>
    </div>
  )
}

export default ListItem
