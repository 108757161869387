"use client"

import * as Blocks from "@components/blocks/export"
import { apiPlugin, storyblokInit } from "@storyblok/react/rsc"

storyblokInit({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    cache: { type: "memory", clear: "auto" }
  },
  components: Object.fromEntries(Object.entries(Blocks))
})

const StoryblokProvider = ({ children }: { children: React.ReactNode }) => {
  return children
}

export default StoryblokProvider
